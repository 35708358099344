import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listenToGameStep } from '../../../actions/projects_actions';

export const useGameStep = (documentId) => {
    const dispatch = useDispatch();
    const gameStep = useSelector(
        ({ projects: { games: inReducerGames } }) => inReducerGames[documentId]
    );
    const hasReceivedGameStep = useSelector(
        ({ projects: { hasReceivedGames: inReducerHasReceivedGames } }) =>
            inReducerHasReceivedGames[documentId]
    );

    useEffect(() => {
        if (hasReceivedGameStep === undefined) {
            console.log('[useGameStep] Listening to game step...', { documentId });
            listenToGameStep(documentId)(dispatch);
        }
    }, [hasReceivedGameStep, documentId, dispatch]);

    return {
        gameStep,
        loading: hasReceivedGameStep === null,
        error: hasReceivedGameStep !== true && hasReceivedGameStep
    };
};
