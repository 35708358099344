import React, { memo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';

import { BadgesList } from './badges_list/badges_list';
import { ErrorTypography } from '../../small_views/error_typography/error_typography';

import { useBadges } from '../../../state/fetchers/miscellaneous/use_badges';

import { styles } from './select_badge_dialog_styles';

const useStyles = makeStyles(styles);

const SelectBadgeDialogComponent = ({ onSelect, open, onClose }) => {
    const classes = useStyles();
    return (
        <Dialog
            {...{
                open,
                onClose
            }}
        >
            <DialogTitle>
                {'Sélectionner un badge ?'}
            </DialogTitle>
            <DialogContent
                classes={{
                    root: classes.content
                }}
            >
                <Content {...{ open, onSelect }} />
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    onClick={onClose}
                >
                    {'Fermer'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Content = memo(({ open, onSelect }) => {
    if (!open) {
        return null;
    }
    return (
        <SelectBadgeDialogContent {...{ onSelect }} />
    );
});

const SelectBadgeDialogContent = ({ onSelect }) => {
    const { loading, error, badges } = useBadges();
    if (loading) {
        return (
            <CircularProgress />
        );
    }
    if (error) {
        return <ErrorTypography {...{ error }} />;
    }
    if (badges) {
        return (
            <BadgesList {...{ onSelect, badges }} />
        );
    }
    return null;
};

export const SelectBadgeDialog = SelectBadgeDialogComponent;
