import { combineReducers } from 'redux';

import UserReducer from './reducers/user_reducer';
import DashboardUsersReducer from './reducers/dashboard_users_reducer';
import MiscellaneousReducer from './reducers/miscellaneous_reducer';
import GameUsersReducer from './reducers/game_users_reducer';
import ProjectsReducer from './reducers/projects_reducer';
import UtilsReducer from './reducers/utils_reducer';
import HomeReducer from './reducers/home_reducer';

export const combinedReducers = combineReducers({
    user: UserReducer,
    dashboardUsers: DashboardUsersReducer,
    miscellaneous: MiscellaneousReducer,
    gameUsers: GameUsersReducer,
    projects: ProjectsReducer,
    utils: UtilsReducer,
    home: HomeReducer
});
