import React, { useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { AppBar, IconButton, Tooltip, Toolbar } from '@material-ui/core';

import {
    PowerSettingsNew as PowerSettingsNewIcon,
    Menu as MenuIcon
} from '@material-ui/icons';

import { logOutUser } from '../../../actions/user_actions';

import { styles } from './content_top_actions_styles';

const useStyles = makeStyles(styles);

const ContentTopActionsComponent = ({ children, isSidePanelTemporary, setSidePanelOpened }) => {
    const classes = useStyles();
    const handleLogout = useCallback(() => {
        logOutUser()();
    }, [logOutUser]);
    return (
        <AppBar
            classes={{
                root: classes.container
            }}
            position="sticky"
            elevation={1}
        >
            <Toolbar classes={{ root: classes.toolbar }}>
                <div className={classes.grow}>
                    {isSidePanelTemporary && (
                        <IconButton onClick={setSidePanelOpened}>
                            <MenuIcon />
                        </IconButton>
                    )}
                </div>
                <Tooltip
                    title="Se déconnecter"
                >
                    <IconButton onClick={handleLogout}>
                        <PowerSettingsNewIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
            {children}
        </AppBar>
    );
};

export const ContentTopActions = ContentTopActionsComponent;
