import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormControl, FormHelperText, InputLabel, Select, MenuItem } from '@material-ui/core';

import { CONTENT_TYPES_DATA } from './content_types_data';

const ContentTypeFieldComponent = ({ field, label, cssSelector, error, form, variant = 'outlined' }) => {
    const [labelWidth, setLabelWidth] = useState();
    const inputLabelReference = useRef();
    const { name, value } = field;
    const { setFieldTouched, setFieldValue } = form;

    useEffect(() => {
        setLabelWidth(inputLabelReference.current.offsetWidth);
    }, []);

    const handleChange = useCallback(
        (event) => {
            const {
                target: { value: newValue }
            } = event;
            setFieldValue(name, newValue);
            setFieldTouched(name, true);
        },
        [name, setFieldTouched, setFieldValue]
    );

    return (
        <FormControl fullWidth {...{ error, variant }}>
            <InputLabel ref={inputLabelReference} id={`content-type-field-label-${cssSelector}`}>
                {label}
            </InputLabel>
            <Select
                labelId={`content-type-field-label-${cssSelector}`}
                id={`content-type-field-${cssSelector}`}
                onChange={handleChange}
                labelWidth={labelWidth}
                {...{ value }}
            >
                {Object.entries(CONTENT_TYPES_DATA).map(
                    ([contentTypeId, { name: contentTypeName, value: contentTypeValue }]) => (
                        <MenuItem
                            key={`content-type-field-label-${cssSelector}_item_${contentTypeId}`}
                            value={contentTypeValue}
                        >
                            {contentTypeName}
                        </MenuItem>
                    )
                )}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};

export const ContentTypeField = ContentTypeFieldComponent;
