import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listenToDraftProjects, listenToDraftProject } from '../../../actions/projects_actions';

export const useDraftProjects = (createdBy) => {
    const dispatch = useDispatch();
    const oldCreatedByReference = useRef(createdBy);
    const unsubscribeListenerReference = useRef();

    const draftProjects = useSelector(
        ({ projects: { draftProjects: inReducerDraftProjects } }) => inReducerDraftProjects
    );
    const hasReceivedAllDraftProjects = useSelector(
        ({ projects: { hasReceivedAllDraftProjects: inReducerHasReceivedAllDraftProjects } }) =>
            inReducerHasReceivedAllDraftProjects
    );

    useEffect(() => {
        const isCreatedByDifferent = createdBy !== oldCreatedByReference.current;
        if (hasReceivedAllDraftProjects === false || isCreatedByDifferent) {
            console.log('[useDraftProjects] Listening to draft projects...', { createdBy });
            if (isCreatedByDifferent && unsubscribeListenerReference.current) {
                unsubscribeListenerReference.current();
            }
            unsubscribeListenerReference.current = listenToDraftProjects(createdBy)(dispatch);
        }
    }, [
        hasReceivedAllDraftProjects,
        dispatch,
        createdBy,
        oldCreatedByReference.current,
        unsubscribeListenerReference.current
    ]);

    useEffect(() => {
        oldCreatedByReference.current = createdBy;
    }, [createdBy]);

    return {
        draftProjects,
        loading: hasReceivedAllDraftProjects === null,
        error: hasReceivedAllDraftProjects !== true && hasReceivedAllDraftProjects
    };
};

export const useDraftProject = (documentId) => {
    const dispatch = useDispatch();
    const draftProject = useSelector(
        ({ projects: { draftProjects: inReducerDraftProjects } }) => inReducerDraftProjects[documentId]
    );
    const hasReceivedDraftProject = useSelector(
        ({ projects: { hasReceivedDraftProjects: inReducerHasReceivedDraftProjects } }) =>
            inReducerHasReceivedDraftProjects[documentId]
    );

    useEffect(() => {
        if (hasReceivedDraftProject === undefined) {
            console.log('[useDraftProject] Listening to draft project...', { documentId });
            listenToDraftProject(documentId)(dispatch);
        }
    }, [hasReceivedDraftProject, documentId]);

    return {
        draftProject,
        loading: hasReceivedDraftProject === null,
        error: hasReceivedDraftProject !== true && hasReceivedDraftProject
    };
};
