import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';

import { App } from './components/app/app';

import createStore from './state/create_store';

import './styles/global.css';
import * as serviceWorker from './serviceWorker';

const store = createStore(process.env.NODE_ENV);

ReactDOM.render((
    <Provider {...{ store }}>
        <App />
    </Provider>
), document.getElementById('root'));

serviceWorker.unregister();
