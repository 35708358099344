const DRAWER_WIDTH = 275;

export const styles = (theme) => ({
    itemCategory: {
        backgroundColor: '#232f3e',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    nestedItem: {
        paddingLeft: theme.spacing(6)
    },
    item: {
        color: 'rgba(255, 255, 255, 0.8)',
        '&:hover,&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)'
        }
    },
    itemText: {
        fontSize: 15
    },
    title: {
        fontSize: 24,
        color: theme.palette.common.white
    },
    itemIcon: {
        color: 'rgba(255, 255, 255, 0.5)',
        minWidth: 'auto',
        marginRight: theme.spacing(2)
    },
    itemPrimary: {
        fontSize: 'inherit'
    },
    container: {
        height: '100vh',
        borderRadius: 0,
        width: 300,
        zIndex: 1
    },
    header: {
        padding: theme.spacing(2, 2),
        backgroundColor: theme.palette.primary.main
    },
    drawer: {
        width: DRAWER_WIDTH
    },
    paper: {
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    divider: {
        margin: theme.spacing(2, 0)
    },
    activeLink: {
        '& > $item': {
            color: 'rgba(255, 255, 255, 1)',
            '& > $itemIcon': {
                color: 'rgba(255, 255, 255, 0.8)'
            }
        }
    }
});
