import React, { useCallback } from 'react';

import { Checkbox, FormGroup, FormControlLabel, FormHelperText } from '@material-ui/core';

const CheckboxFieldComponent = ({ field, step, label, cssSelector, error, form, type, variant = 'outlined' }) => {
    const { name, value, onBlur } = field;

    const onChange = useCallback(
        (event) => {
            const {
                target: { checked }
            } = event;
            form.setFieldValue(name, checked);
            form.setFieldTouched(name, true);
        },
        [form, type]
    );

    return (
        <FormGroup row {...{ error, variant }}>
            <FormControlLabel
                control={(
                    <Checkbox
                        id={`${step}_form_${cssSelector}`}
                        checked={value}
                        value={`${step}_form_${cssSelector}`}
                        color="primary"
                        {...{
                            onChange,
                            onBlur
                        }}
                    />
                )}
                {...{
                    label
                }}
            />
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormGroup>
    );
};

export const CheckboxField = CheckboxFieldComponent;
