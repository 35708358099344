import React, { useEffect, useRef } from 'react';

const ManageMediasComponent = () => {
    const containerReference = useRef();

    useEffect(() => {
        const embedded = document.getElementById('wt_embed');
        embedded.style.display = 'initial';
        containerReference.current.appendChild(embedded);
        return () => {
            embedded.style.display = 'none';
            document.body.appendChild(embedded);
        };
    }, []);

    return <div ref={containerReference} />;
};

export const ManageMedias = ManageMediasComponent;
