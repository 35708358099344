import { flex } from '../../../../../../../../utils/styles_utils';

const { center } = flex;

export const styles = (theme) => ({
    container: {
        marginTop: theme.spacing(2),
        height: 150,
        overflow: 'hidden',
        ...center
    },
    contentImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover'
    }
});
