import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from "react-player";

import { Paper } from '@material-ui/core';

import { styles } from './tutorials_styles';

const useStyles = makeStyles(styles);

const TutorialsComponent = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Paper classes={{
                root: classes.iframeContainer
            }}
            >
                <ReactPlayer
                    url="https://www.youtube.com/embed/videoseries?list=PLwlF1uffYtXrBWkdhf9AJtrSws-djZ_aX"
                />
            </Paper>
        </div>
    );
};

export const Tutorials = TutorialsComponent;
