import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress, Typography } from '@material-ui/core';

import { ContentHeader } from '../small_views/content_header/content_header';
import { GameUsersTable } from './game_users_table/game_users_table';

import { useGameUsers } from '../../state/fetchers/game_users/use_game_users';

import { styles } from './manage_game_users_styles';

const useStyles = makeStyles(styles);

const ManageGameUsersComponent = () => {
    const classes = useStyles();
    const { gameUsers, loading, error } = useGameUsers();
    const gameUsersLength = useMemo(() =>
        gameUsers && Object.keys(gameUsers).length, [gameUsers]);
    return (
        <>
            <ContentHeader
                title={`${gameUsersLength ? `${gameUsersLength} ` : ''}Utilisateurs - Jeu`}
            />
            <div className={classes.container}>
                <Content
                    {...{
                        gameUsers,
                        loading,
                        error
                    }}
                />
            </div>
        </>
    );
};

const Content = ({
    gameUsers,
    loading,
    error
}) => {
    if (loading) {
        return (
            <CircularProgress />
        );
    }
    if (error) {
        return <Error {...{ error }} />;
    }
    if (gameUsers) {
        return <GameUsersTable {...{ gameUsers }} />;
    }
    return null;
};

const Error = ({ error }) => (
    <Typography>
        {(error && error.message) || error}
    </Typography>
);

export const ManageGameUsers = ManageGameUsersComponent;
