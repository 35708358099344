import firebase from '../../firebase';

import {
    DATABASE_EDIT_SUBMIT,
    DATABASE_EDIT_FINISH,
    LISTEN_TO_BADGES_STARTED,
    LISTEN_TO_BADGES_RECEIVED_DATA,
    LISTEN_TO_BADGES_RECEIVED_ERROR
} from '../types';

import { getObjectFromCollectionSnapshot } from '../../utils/firestore_utils';

const database = firebase.firestore();

export const listenToBadges = () => (dispatch) => {
    dispatch({
        type: LISTEN_TO_BADGES_STARTED
    });
    return database.collection('badges').onSnapshot((snapshot) => {
        dispatch({
            type: LISTEN_TO_BADGES_RECEIVED_DATA,
            badges: getObjectFromCollectionSnapshot(snapshot)
        });
    }, (error) => {
        dispatch({
            type: LISTEN_TO_BADGES_RECEIVED_ERROR,
            error
        });
    });
};

export const setBadge = (badgeId, badge) => (dispatch) => {
    dispatch({
        type: DATABASE_EDIT_SUBMIT
    });
    return database.collection('badges').doc(badgeId).set(badge)
        .finally(() => dispatch({
            type: DATABASE_EDIT_FINISH
        }));
};

export const deleteBadge = (badgeId) => (dispatch) => {
    dispatch({
        type: DATABASE_EDIT_SUBMIT
    });
    return database.collection('badges').doc(badgeId).delete()
        .finally(() => dispatch({
            type: DATABASE_EDIT_FINISH
        }));
};
