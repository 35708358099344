export const styles = (theme) => ({
    textField: {
        margin: theme.spacing(2, 0)
    },
    gridContainer: {
        margin: theme.spacing(0.5, 0, 0.5, -1.5)
    },
    uploadButton: {
        height: '100%',
        width: '100%'
    }
});
