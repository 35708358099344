import { GeneralForm } from '../general_form/general_form';
import { GameSteps } from '../game_steps/game_steps';

export const STEPS_DATA = Object.freeze({
    general: {
        param: 'general',
        name: 'Paramètres généraux',
        component: GeneralForm
    },
    gameSteps: {
        param: 'game-steps',
        name: 'Étapes',
        component: GameSteps
    }
});
