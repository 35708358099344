import React, { useState, useMemo, useCallback } from 'react';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';

const EditEntryDialogComponent = ({ open, onClose, value: receivedValue, onSave }) => {
    const [value, setValue] = useState(receivedValue);
    const disabled = useMemo(() => !value || value === receivedValue, [value, receivedValue]);

    const handleChange = useCallback((event) => {
        const {
            target: { value: newValue }
        } = event;
        setValue(newValue);
    }, []);

    const handleSave = useCallback(() => {
        if (disabled) {
            return;
        }
        if (typeof onSave === 'function') {
            onSave(value);
            onClose();
        }
    }, [value, disabled, onSave]);

    return (
        <Dialog
            {...{
                open,
                onClose
            }}
        >
            <DialogTitle>{'Editer une entrée ?'}</DialogTitle>
            <DialogContent>
                <TextField variant="outlined" label="Valeur de l'entrée" onChange={handleChange} {...{ value }} />
            </DialogContent>
            <DialogActions>
                <Button size="small" onClick={onClose}>
                    {'Fermer'}
                </Button>
                <Button size="small" color="primary" onClick={handleSave} {...{ disabled }}>
                    {'Sauvegarder'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const EditEntryDialog = EditEntryDialogComponent;
