import {
    LISTEN_TO_DASHBOARD_USERS_STARTED,
    LISTEN_TO_DASHBOARD_USERS_RECEIVED_DATA,
    LISTEN_TO_DASHBOARD_USERS_RECEIVED_ERROR
} from '../../actions/types';

const initialState = {
    hasReceivedDashboardUsers: false,
    dashboardUsers: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
    case LISTEN_TO_DASHBOARD_USERS_STARTED:
        return {
            ...state,
            hasReceivedDashboardUsers: null
        };

    case LISTEN_TO_DASHBOARD_USERS_RECEIVED_DATA:
        return {
            ...state,
            hasReceivedDashboardUsers: true,
            dashboardUsers: action.dashboardUsers
        };

    case LISTEN_TO_DASHBOARD_USERS_RECEIVED_ERROR:
        return {
            ...state,
            hasReceivedDashboardUsers: action.error
        };

    default:
        return state;
    }
};
