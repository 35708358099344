import {
    LISTEN_TO_PROJECTS_STARTED,
    LISTEN_TO_PROJECTS_RECEIVED_DATA,
    LISTEN_TO_PROJECTS_RECEIVED_ERROR,
    LISTEN_TO_PROJECT_STARTED,
    LISTEN_TO_PROJECT_RECEIVED_DATA,
    LISTEN_TO_PROJECT_RECEIVED_ERROR,
    LISTEN_TO_DRAFT_PROJECTS_STARTED,
    LISTEN_TO_DRAFT_PROJECTS_RECEIVED_DATA,
    LISTEN_TO_DRAFT_PROJECTS_RECEIVED_ERROR,
    LISTEN_TO_DRAFT_PROJECT_STARTED,
    LISTEN_TO_DRAFT_PROJECT_RECEIVED_DATA,
    LISTEN_TO_DRAFT_PROJECT_RECEIVED_ERROR,
    LISTEN_TO_GAME_STARTED,
    LISTEN_TO_GAME_RECEIVED_DATA,
    LISTEN_TO_GAME_RECEIVED_ERROR
} from '../../actions/types';

const initialState = {
    hasReceivedAllProjects: false,
    hasReceivedProjects: {},
    projects: {},
    hasReceivedAllDraftProjects: false,
    hasReceivedDraftProjects: {},
    draftProjects: {},
    hasReceivedGames: {},
    games: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
    case LISTEN_TO_PROJECTS_STARTED:
        return {
            ...state,
            hasReceivedAllProjects: null
        };

    case LISTEN_TO_PROJECTS_RECEIVED_DATA:
        return {
            ...state,
            hasReceivedAllProjects: true,
            hasReceivedProjects: {
                ...state.hasReceivedProjects,
                ...Object.keys(action.projects).reduce((acc, documentId) => {
                    const accCopy = acc;
                    accCopy[documentId] = true;
                    return accCopy;
                }, {})
            },
            projects: action.projects
        };

    case LISTEN_TO_PROJECTS_RECEIVED_ERROR:
        return {
            ...state,
            hasReceivedProjects: action.error
        };

    case LISTEN_TO_PROJECT_STARTED:
        return {
            ...state,
            hasReceivedProjects: {
                ...state.hasReceivedProjects,
                [action.documentId]: null
            }
        };

    case LISTEN_TO_PROJECT_RECEIVED_DATA:
        return {
            ...state,
            hasReceivedProjects: {
                ...state.hasReceivedProjects,
                [action.documentId]: true
            },
            projects: {
                ...state.projects,
                [action.documentId]: action.project
            }
        };

    case LISTEN_TO_PROJECT_RECEIVED_ERROR:
        return {
            ...state,
            hasReceivedProjects: {
                ...state.hasReceivedProjects,
                [action.documentId]: action.error
            }
        };

    case LISTEN_TO_DRAFT_PROJECTS_STARTED:
        return {
            ...state,
            hasReceivedAllDraftProjects: null
        };

    case LISTEN_TO_DRAFT_PROJECTS_RECEIVED_DATA:
        return {
            ...state,
            hasReceivedAllDraftProjects: true,
            hasReceivedDraftProjects: {
                ...state.hasReceivedDraftProjects,
                ...Object.keys(action.draftProjects).reduce((acc, documentId) => {
                    const accCopy = acc;
                    accCopy[documentId] = true;
                    return accCopy;
                }, {})
            },
            draftProjects: action.draftProjects
        };

    case LISTEN_TO_DRAFT_PROJECTS_RECEIVED_ERROR:
        return {
            ...state,
            hasReceivedAllDraftProjects: action.error
        };

    case LISTEN_TO_DRAFT_PROJECT_STARTED:
        return {
            ...state,
            hasReceivedDraftProjects: {
                ...state.hasReceivedDraftProjects,
                [action.documentId]: null
            }
        };

    case LISTEN_TO_DRAFT_PROJECT_RECEIVED_DATA:
        return {
            ...state,
            hasReceivedDraftProjects: {
                ...state.hasReceivedDraftProjects,
                [action.documentId]: true
            },
            draftProjects: {
                ...state.draftProjects,
                [action.documentId]: action.draftProject
            }
        };

    case LISTEN_TO_DRAFT_PROJECT_RECEIVED_ERROR:
        return {
            ...state,
            hasReceivedDraftProjects: {
                ...state.hasReceivedDraftProjects,
                [action.documentId]: action.error
            }
        };

    case LISTEN_TO_GAME_STARTED:
        return {
            ...state,
            hasReceivedGames: {
                ...state.hasReceivedGames,
                [action.documentId]: null
            }
        };

    case LISTEN_TO_GAME_RECEIVED_DATA:
        return {
            ...state,
            hasReceivedGames: {
                ...state.hasReceivedGames,
                [action.documentId]: true
            },
            games: {
                ...state.games,
                [action.documentId]: action.game
            }
        };

    case LISTEN_TO_GAME_RECEIVED_ERROR:
        return {
            ...state,
            hasReceivedGames: {
                ...state.hasReceivedGames,
                [action.documentId]: action.error
            }
        };

    default:
        return state;
    }
};
