export const styles = ({
    container: {
        height: 60,
        backgroundColor: '#fff'
    },
    toolbar: {
        height: '100%',
        maxHeight: '100%',
        minHeight: 'unset !important'
    },
    grow: {
        flex: 1
    }
});
