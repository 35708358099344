import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { NotLoggedLanding } from '../../not_logged_landing/not_logged_landing';

const AnonymousRoutesComponent = () => (
    <Switch>
        <Route
            path="*"
            component={NotLoggedLanding}
        />
    </Switch>
);

export const AnonymousRoutes = AnonymousRoutesComponent;
