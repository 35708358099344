import firebase from '../firebase';

import {
    DATABASE_EDIT_SUBMIT,
    DATABASE_EDIT_FINISH,
    LISTEN_TO_DASHBOARD_USERS_STARTED,
    LISTEN_TO_DASHBOARD_USERS_RECEIVED_DATA,
    LISTEN_TO_DASHBOARD_USERS_RECEIVED_ERROR
} from './types';

import { getObjectFromCollectionSnapshot } from '../utils/firestore_utils';

const database = firebase.firestore();

export const listenToDashboardUsers = () => (dispatch) => {
    dispatch({
        type: LISTEN_TO_DASHBOARD_USERS_STARTED
    });
    return database.collection('dashboard_users').onSnapshot(
        (snapshot) => {
            dispatch({
                type: LISTEN_TO_DASHBOARD_USERS_RECEIVED_DATA,
                dashboardUsers: getObjectFromCollectionSnapshot(snapshot)
            });
        },
        (error) => {
            dispatch({
                type: LISTEN_TO_DASHBOARD_USERS_RECEIVED_ERROR,
                error
            });
        }
    );
};

export const setDashboardUserRole = (email, role) => (dispatch) => {
    dispatch({
        type: DATABASE_EDIT_SUBMIT
    });
    return database
        .collection('dashboard_users')
        .doc(email)
        .update({
            role
        })
        .finally(() =>
            dispatch({
                type: DATABASE_EDIT_FINISH
            }));
};

export const addDashboardUser = (email, dashboardUser) => (dispatch) => {
    dispatch({
        type: DATABASE_EDIT_SUBMIT
    });
    return database
        .collection('dashboard_users')
        .doc(email)
        .set(dashboardUser)
        .finally(() =>
            dispatch({
                type: DATABASE_EDIT_FINISH
            }));
};

export const deleteDashboardUser = (email) => (dispatch) => {
    dispatch({
        type: DATABASE_EDIT_SUBMIT
    });
    return database
        .collection('dashboard_users')
        .doc(email)
        .delete()
        .finally(() =>
            dispatch({
                type: DATABASE_EDIT_FINISH
            }));
};
