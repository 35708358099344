import React, { useCallback, useMemo, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Button, CircularProgress, Typography } from '@material-ui/core';

import { ContentHeader } from '../small_views/content_header/content_header';
import { BadgesTable } from './badges_table/badges_table';
import { EditBadgeDialog } from './edit_badge_dialog/edit_badge_dialog';

import { useBadges } from '../../state/fetchers/miscellaneous/use_badges';

import { styles } from './badges_styles';

const useStyles = makeStyles(styles);

export const BadgesComponent = () => {
    const classes = useStyles();
    const { badges, loading, error } = useBadges();
    const badgesLength = useMemo(() => badges && Object.keys(badges).length, [badges]);
    return (
        <>
            <Header {...{ badgesLength }} />
            <div className={classes.container}>
                <Content
                    {...{
                        badges,
                        loading,
                        error
                    }}
                    />
            </div>
        </>
    );
};

const Header = ({ badgesLength }) => {
    const [openAddBadgeDialog, setAddBadgeDialogOpenState] = useState(false);
    const setAddBadgeDialogOpened = useCallback(() => setAddBadgeDialogOpenState(true), []);
    const setAddBadgeDialogClosed = useCallback(() => setAddBadgeDialogOpenState(false), []);
    return (
        <>
            <EditBadgeDialog
                open={openAddBadgeDialog}
                onClose={setAddBadgeDialogClosed}
            />
            <ContentHeader
                title={`${badgesLength ? `${badgesLength} ` : ''}Badges`}
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={setAddBadgeDialogOpened}
                >
                    {'Ajouter un badge'}
                </Button>
            </ContentHeader>
        </>
    );
};

const Content = ({
    badges,
    loading,
    error
}) => {
    if (loading) {
        return (
            <CircularProgress />
        );
    }
    if (error) {
        return <Error {...{ error }} />;
    }
    if (badges) {
        return (
            <BadgesTable
                {...{
                    badges
                }}
            />
        );
    }
    return null;
};

const Error = ({ error }) => (
    <Typography>
        {(error && error.message) || error}
    </Typography>
);

export const Badges = BadgesComponent;
