export const styles = (theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card: {
        width: 1,
        minWidth: '100%'
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: `${theme.spacing(5)}px !important`,
        paddingBottom: `${theme.spacing(4)}px !important`
    },
    stepper: {
        width: '100%',
        maxWidth: 600,
        marginBottom: theme.spacing(2),
        backgroundColor: 'unset'
    }
});
