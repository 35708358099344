import {
    LISTEN_TO_HOME_CARD_STARTED,
    LISTEN_TO_HOME_CARD_RECEIVED_DATA,
    LISTEN_TO_HOME_CARD_RECEIVED_ERROR
} from '../../actions/types';

const initialState = {
    hasReceivedCards: {},
    cards: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
    case LISTEN_TO_HOME_CARD_STARTED:
        return {
            ...state,
            hasReceivedCards: {
                ...state.hasReceivedCards,
                [action.cardId]: null
            }
        };

    case LISTEN_TO_HOME_CARD_RECEIVED_DATA:
        return {
            ...state,
            hasReceivedCards: {
                ...state.hasReceivedCards,
                [action.cardId]: true
            },
            cards: {
                ...state.cards,
                [action.cardId]: action.card
            }
        };

    case LISTEN_TO_HOME_CARD_RECEIVED_ERROR:
        return {
            ...state,
            hasReceivedCards: {
                ...state.hasReceivedCards,
                [action.cardId]: action.error
            }
        };

    default:
        return state;
    }
};
