import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { LinearProgress } from '@material-ui/core';

import { useIsEditingDatabase } from '../../../state/selectors/utils_selectors';

import { styles } from './editing_database_linear_progress_styles';

const useStyles = makeStyles(styles);

const EditingDatabaseLinearProgressComponent = () => {
    const classes = useStyles();
    const isEditingDatabase = useIsEditingDatabase();
    if (!isEditingDatabase) {
        return null;
    }
    return (
        <LinearProgress
            color="secondary"
            classes={{
                root: classes.linearProgress
            }}
        />
    );
};

export const EditingDatabaseLinearProgress = EditingDatabaseLinearProgressComponent;
