export const styles = (theme) => ({
    container: {
        display: 'flex'
    },
    textField: {
        marginRight: theme.spacing(3)
    },
    uploadButton: {
        height: '100%'
    },
    circularProgress: {
        marginRight: theme.spacing(1.5)
    }
});
