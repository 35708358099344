export const styles = (theme) => ({
    contentHeaderTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    divider: {
        height: 20,
        width: 1,
        backgroundColor: theme.palette.grey[500],
        margin: theme.spacing(0, 3)
    },
    container: {
        display: 'flex',
        justifyContent: 'center'
    },
    '@media screen and (max-width: 480px)': {
        contentHeaderTitle: {
            flexDirection: 'column',
            marginBottom: theme.spacing(4),
            alignItems: 'flex-start'
        },
        divider: {
            height: 1,
            width: '100%',
            maxWidth: 50,
            margin: theme.spacing(0.5, 0, 1.5)
        }
    }
});
