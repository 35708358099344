import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { combinedReducers } from './combined_reducers';

export default (nodeEnv) => {
    if (nodeEnv === 'development') {
        const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        return createStore(combinedReducers, composeEnhancers(applyMiddleware(thunkMiddleware)));
    }
    return createStore(combinedReducers, applyMiddleware(thunkMiddleware));
};
