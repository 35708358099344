import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Card, CardContent, LinearProgress, Typography } from '@material-ui/core';

import { CardTitle } from '../../../small_views/card_title/card_title';
import { EditableButton } from '../../../small_views/editable_button/editable_button';
import { EditObjectiveDialog } from './edit_objective_dialog/edit_objective_dialog';

import { useDashboardUserRole } from '../../../../state/selectors/user_selectors';
import { useCallbackOpen } from '../../../../hooks/use_callback_open';

import { styles } from './objective_card_styles';

const useStyles = makeStyles(styles);

const ObjectiveCardComponent = ({ documentId, card, name, color }) => {
    const classes = useStyles();
    const [openEditObjectiveDialog, setEditObjectiveDialogOpened, setEditObjectiveDialogClosed] = useCallbackOpen();
    const { objective, value } = card || {};
    const percentage = useMemo(() => value * (100 / objective), [value, objective]);
    const role = useDashboardUserRole();
    const isAdmin = useMemo(() => role === 'admin', [role]);
    if (!card) {
        return null;
    }
    return (
        <>
            <EditObjectiveDialog
                open={openEditObjectiveDialog}
                onClose={setEditObjectiveDialogClosed}
                {...{ objective, documentId }}
            />
            <Card>
                <CardContent>
                    <CardTitle>{name}</CardTitle>
                    <Typography classes={{ root: classes.objectiveTypography }}>
                        {'Objectif : '}
                        <ObjectiveNode {...{ isAdmin, objective, setEditObjectiveDialogOpened }} />
                    </Typography>
                    <div className={classes.progressContainer}>
                        <Typography
                            classes={{
                                root: classes.progressTypography
                            }}
                        >
                            {value}
                        </Typography>
                        <LinearProgress
                            classes={{
                                root: classes.progressRoot
                            }}
                            value={value > objective ? 100 : percentage}
                            variant="determinate"
                            {...{ color }}
                        />
                    </div>
                </CardContent>
            </Card>
        </>
    );
};

const ObjectiveNode = ({ isAdmin, objective, setEditObjectiveDialogOpened }) => {
    if (!isAdmin) {
        return objective || '?';
    }
    return <EditableButton onClick={setEditObjectiveDialogOpened}>{objective || '?'}</EditableButton>;
};

export const ObjectiveCard = ObjectiveCardComponent;
