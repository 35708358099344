import React, { useEffect, useState, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Phone } from '../../../../../../small_views/phone/phone';
import { PhoneBackground } from '../../../../../../small_views/phone/phone_background/phone_background';
import { GameStepPhoneTitle } from './game_step_phone_title/game_step_phone_title';
import { GameStepPhoneContent } from './game_step_phone_content/game_step_phone_content';
import { GameStepPhoneQuestion } from './game_step_phone_question/game_step_phone_question';
import { GameStepPhoneAnswer } from './game_step_phone_answer/game_step_phone_answer';
import { GameStepPhoneActions } from './game_step_phone_actions/game_step_phone_actions';

import { styles } from './game_step_phone_styles';

const useStyles = makeStyles(styles);

export const GameStepPhoneComponent = ({ values, language = 'fr', baseProjectNames, templateNumber }) => {
    const [containerWidth, setContainerWidth] = useState(0);
    const containerReference = useRef();
    useEffect(() => {
        setContainerWidth(containerReference.current.offsetWidth);
    }, []);
    const classes = useStyles({ containerWidth });
    const { questions, answers, backgroundColor, contentType, content } = values;
    return (
        <div ref={containerReference} className={classes.container}>
            <Phone>
                <PhoneBackground
                    customClasses={{
                        container: classes.background
                    }}
                    style={{
                        fill: backgroundColor
                    }}
                />
            </Phone>
            <div className={classes.contentContainer}>
                <div className={classes.content}>
                    <GameStepPhoneTitle>
                        {baseProjectNames && baseProjectNames[language]}
                    </GameStepPhoneTitle>
                    <GameStepPhoneContent {...{ contentType, content }} />
                    <GameStepPhoneQuestion>
                        {questions && questions[language]}
                    </GameStepPhoneQuestion>
                    <GameStepPhoneAnswer {...{ templateNumber }}>
                        {answers && answers[language]}
                    </GameStepPhoneAnswer>
                    <GameStepPhoneActions {...{ templateNumber }} />
                </div>
            </div>
        </div>
    );
};

export const GameStepPhone = GameStepPhoneComponent;
