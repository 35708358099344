import React, { useCallback } from 'react';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';

const GenericConfirmDialogComponent = ({
    title = 'Êtes-vous sûr ?',
    content = 'Cette action est irréversible, êtes-vous sûr de vouloir continuer ?',
    onClose,
    onConfirm,
    open
}) => {
    const handleClose = useCallback(() => {
        if (typeof onClose === 'function') {
            onClose();
        }
    }, [onClose]);

    const handleConfirm = useCallback(() => {
        if (typeof onConfirm === 'function') {
            onConfirm();
        }
    }, [onConfirm]);
    return (
        <Dialog {...{ open }} onClose={handleClose}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    onClick={handleClose}
                >
                    {'Fermer'}
                </Button>
                <Button
                    color="primary"
                    size="small"
                    onClick={handleConfirm}
                >
                    {'Confirmer'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const GenericConfirmDialog = GenericConfirmDialogComponent;
