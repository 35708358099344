import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listenToDashboardUsers } from '../../../actions/dashboard_users_actions';
import { listenToProject } from '../../../actions/projects_actions';

export const useDashboardUsers = () => {
    const dashboardUsers = useSelector(
        ({ dashboardUsers: { dashboardUsers: inReducerDashboardUsers } }) => inReducerDashboardUsers
    );
    const hasReceivedDashboardUsers = useSelector(
        ({ dashboardUsers: { hasReceivedDashboardUsers: inReducerHasReceivedDashboardUsers } }) =>
            inReducerHasReceivedDashboardUsers
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (hasReceivedDashboardUsers === false) {
            listenToProject('evian_test')(dispatch);
            console.log('[useDashboardUsers] Listening to dashboard users...');
            listenToDashboardUsers()(dispatch);
        }
    }, [hasReceivedDashboardUsers]);

    return {
        dashboardUsers,
        loading: hasReceivedDashboardUsers === null,
        error: hasReceivedDashboardUsers !== true && hasReceivedDashboardUsers
    };
};
