import { useSelector } from 'react-redux';

export const useIsWaitingForAuthStateChange = () =>
    useSelector(({ user: { isWaitingForAuthStateChange } }) => isWaitingForAuthStateChange);

export const useUid = () => useSelector(({ user: { uid } }) => uid);

export const useHasReceivedUid = () => useSelector(({ user: { hasReceivedUid } }) => hasReceivedUid);

export const useUserEmail = () => useSelector(({ user: { email } }) => email);

export const useDashboardUserRole = () => useSelector(({ user: { dashboardUser } }) => dashboardUser && dashboardUser.role);
