import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress, Grid } from '@material-ui/core';

import { ContentHeader } from '../small_views/content_header/content_header';
import { ErrorTypography } from '../small_views/error_typography/error_typography';

import { HOME_CARDS_DATA } from './home_cards_data';

import { useHomeCard } from '../../state/fetchers/home/use_home_card';

import { styles } from './home_styles';

const useStyles = makeStyles(styles);

const HomeComponent = () => {
    const classes = useStyles();
    return (
        <>
            <ContentHeader />
            <Grid container spacing={3}>
                {Object.entries(HOME_CARDS_DATA).map(
                    ([cardId, { documentId, component, gridDimensions, ...other }]) => (
                        <WithFetcherComponent
                            key={`home_card_${cardId}`}
                            {...{
                                documentId,
                                component,
                                gridDimensions,
                                ...other
                            }}
                        />
                    )
                )}
            </Grid>
        </>
    );
};

const WithFetcherComponent = ({ component: Component, documentId, cardId, gridDimensions, ...other }) => {
    const { loading, error, card } = useHomeCard(documentId);
    if (loading) {
        return <CircularProgress />;
    }
    if (error) {
        return <ErrorTypography {...{ error }} />;
    }
    return (
        <Grid item {...gridDimensions}>
            <Component {...{ documentId, cardId, card, ...other }} />
        </Grid>
    );
};

export const Home = HomeComponent;
