import { createMuiTheme } from '@material-ui/core/styles';

let muiTheme = createMuiTheme({
    palette: {
        primary: {
            light: 'rgb(71, 145, 219)',
            main: '#1976d2',
            dark: '#0052cc'
        }
    },
    shadows: [
        'none',
        '0 0 14px 0 rgba(53,64,82,.05)',
        ...new Array(6).fill('0 10px 25px 0 rgba(0, 0, 0, .06)'),
        '0 10px 25px 0 rgba(0, 0, 0, .1)',
        ...new Array(30).fill('0 10px 25px 0 rgba(0, 0, 0, .1)')
    ],
    typography: {
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5
        }
    }
});

muiTheme = {
    ...muiTheme,
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: '#1b2430'
            }
        },
        MuiDivider: {
            root: {
                backgroundColor: '#404854'
            }
        },
        MuiCard: {
            root: {
                height: 'fit-content'
            }
        },
        MuiListItem: {
            gutters: {
                paddingLeft: 24,
                paddingRight: 24
            }
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32
            }
        }
    }
};

export const theme = muiTheme;
