import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import {Button, Typography, useMediaQuery} from '@material-ui/core';

import { useIsWaitingForAuthStateChange } from '../../state/selectors/user_selectors';

import { loginWithProvider } from '../../actions/user_actions';

import { styles } from './not_logged_landing_styles';

const useStyles = makeStyles(styles);

const NotLoggedLandingComponent = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isWaitingForAuthStateChange = useIsWaitingForAuthStateChange();
    const handleClick = useCallback(() => {
        loginWithProvider('google')(dispatch);
    }, [dispatch, loginWithProvider]);
    const isSmallMobile = useMediaQuery('(max-width: 359px)');
    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <Typography
                    classes={{
                        root: classes.title
                    }}
                    variant={isSmallMobile ? 'h3' : 'h2'}
                >
                    <Title {...{ isWaitingForAuthStateChange }} />
                </Typography>
                <Typography
                    classes={{
                        root: classes.subTitle
                    }}
                    variant={isSmallMobile ? 'h6' : 'h5'}
                >
                    <SubTitle {...{ isWaitingForAuthStateChange }} />
                </Typography>
                {!isWaitingForAuthStateChange && (
                    <Button
                        classes={{
                            root: classes.button
                        }}
                        color="primary"
                        variant="contained"
                        onClick={handleClick}
                    >
                        {'Se connecter avec un compte Google'}
                    </Button>
                )}
            </div>
        </div>
    );
};

const Title = ({ isWaitingForAuthStateChange }) => {
    if (isWaitingForAuthStateChange) {
        return 'Merci de patienter...';
    }
    return "Vous n'êtes pas connecté.";
};

const SubTitle = ({ isWaitingForAuthStateChange }) => {
    if (isWaitingForAuthStateChange) {
        return 'Nous vérifions si vous êtes connecté, cette opération peut prendre quelques secondes.';
    }
    return 'Vous devez être connecté pour profiter des fonctionnalités de ce fantastique dashboard !';
};

export const NotLoggedLanding = NotLoggedLandingComponent;
