import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { CardContent, CircularProgress, Divider, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { ErrorTypography } from '../../../../../small_views/error_typography/error_typography';
import { GameStepForm } from './game_step_form/game_step_form';

import { GAME_STEP_TEMPLATES } from './game_step_templates';

import { useGameStep } from '../../../../../../state/fetchers/projects/use_game_step';

import { styles } from './game_step_styles';

const useStyles = makeStyles(styles);

const getDefaultTemplateId = (gameStep) => {
    if (gameStep && gameStep.template) {
        const templateEntry = Object.entries(GAME_STEP_TEMPLATES).find(
            ([, { templateNumber }]) => templateNumber === gameStep.template
        );
        if (templateEntry) {
            return templateEntry[0];
        }
    }
    return Object.keys(GAME_STEP_TEMPLATES)[0];
};

const GameStepComponent = ({
    projectDocumentId,
    documentId,
    status,
    gameRefs,
    currentGameStepIndex,
    baseProjectNames
}) => {
    const classes = useStyles();
    return (
        <CardContent classes={{ root: classes.container }}>
            <Content
                {...{
                    projectDocumentId,
                    documentId,
                    currentGameStepIndex,
                    baseProjectNames,
                    status,
                    gameRefs,
                    classes
                }}
            />
        </CardContent>
    );
};

const Content = ({
    projectDocumentId,
    currentGameStepIndex,
    status,
    documentId,
    gameRefs,
    baseProjectNames,
    classes
}) => {
    const { loading, error, gameStep } = useGameStep(documentId);
    if (loading) {
        return <CircularProgress color="primary" />;
    }
    if (error) {
        return <ErrorTypography {...{ error }} />;
    }
    return (
        <WithGameStepContent
            key={`game_step_content_${projectDocumentId}_${documentId}`}
            {...{
                projectDocumentId,
                documentId,
                gameStep,
                status,
                currentGameStepIndex,
                gameRefs,
                baseProjectNames,
                classes
            }}
        />
    );
};

const WithGameStepContent = ({
    gameStep,
    projectDocumentId,
    documentId,
    status,
    currentGameStepIndex,
    gameRefs,
    baseProjectNames,
    classes
}) => {
    const [templateId, setTemplateId] = useState(getDefaultTemplateId(gameStep));
    return (
        <div className={classes.fields}>
            <TemplateSelect {...{ templateId, setTemplateId, classes }} />
            <Divider className={classes.divider} />
            <GameStepForm
                {...{
                    gameStep,
                    projectDocumentId,
                    documentId,
                    templateId,
                    status,
                    gameRefs,
                    currentGameStepIndex,
                    baseProjectNames
                }}
            />
        </div>
    );
};

const TemplateSelect = memo(({ templateId, setTemplateId, classes }) => {
    const inputLabelReference = useRef();
    const [labelWidth, setLabelWidth] = useState(0);

    const handleChange = useCallback(
        (event) => {
            const {
                target: { value }
            } = event;
            setTemplateId(value);
        },
        [setTemplateId]
    );

    useEffect(() => {
        setLabelWidth(inputLabelReference.current.offsetWidth);
    }, []);

    return (
        <FormControl variant="outlined" classes={{ root: classes.formControl }}>
            <InputLabel ref={inputLabelReference} id="edit-project-game-step-select-template-label">
                {'Template'}
            </InputLabel>
            <Select
                classes={{
                    root: classes.select
                }}
                labelId="edit-project-game-step-select-template-label"
                id="edit-project-game-step-select-template"
                value={templateId}
                onChange={handleChange}
                labelWidth={labelWidth}
            >
                {Object.entries(GAME_STEP_TEMPLATES).map(([id, { name }]) => (
                    <MenuItem key={`edit_project_game_step_select_template_item_${id}`} value={id}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});

export const GameStep = GameStepComponent;
