import {
    AUTH_STATE_CHANGED_RECEIVED_USER,
    AUTH_STATE_CHANGED_NO_USER,
    LOGIN_USER_WITH_EMAIL_STARTED,
    LISTEN_TO_DASHBOARD_USER_STARTED,
    LISTEN_TO_DASHBOARD_USER_RECEIVED_DATA,
    LISTEN_TO_DASHBOARD_USER_RECEIVED_ERROR
} from '../../actions/types';

const initialState = {
    isWaitingForAuthStateChange: true,
    hasReceivedUid: false,
    uid: null,
    email: null,
    hasReceivedDashboardUser: false,
    dashboardUser: null
};

export default (state = initialState, action) => {
    switch (action.type) {
    case AUTH_STATE_CHANGED_RECEIVED_USER:
        return {
            ...state,
            isWaitingForAuthStateChange: false,
            hasReceivedUid: true,
            uid: action.uid,
            email: action.email
        };

    case AUTH_STATE_CHANGED_NO_USER:
        return {
            ...state,
            isWaitingForAuthStateChange: false,
            hasReceivedUid: false
        };

    case LOGIN_USER_WITH_EMAIL_STARTED:
        return { ...state, hasReceivedUid: null };

    case LISTEN_TO_DASHBOARD_USER_STARTED:
        return {
            ...state,
            hasReceivedDashboardUser: null
        };

    case LISTEN_TO_DASHBOARD_USER_RECEIVED_DATA:
        return {
            ...state,
            hasReceivedDashboardUser: true,
            dashboardUser: action.dashboardUser
        };

    case LISTEN_TO_DASHBOARD_USER_RECEIVED_ERROR:
        return {
            ...state,
            hasReceivedDashboardUser: action.error
        }
    default:
        return state;
    }
};
