import React, { useCallback } from 'react';

import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { TextField } from '@material-ui/core';

import { styles } from './clickable_text_field_styles';

const useStyles = makeStyles(styles);

const ClickableTextFieldComponent = ({
    interactionsLayerRef,
    onClick,
    onFocus,
    textFieldIconProps,
    customClasses = {},
    ...other
}) => {
    const classes = useStyles();
    const handleFocus = useCallback((event, ...args) => {
        if (typeof onFocus === 'function') {
            onFocus(event, ...args);
        }
        const { target } = event;
        target.blur();
    }, [onFocus]);
    return (
        <div className={cn(classes.container, customClasses.container)}>
            <TextField
                inputProps={{
                    tabIndex: '-1'
                }}
                className={cn(classes.textField, customClasses.textField)}
                {...other}
            />
            <button
                ref={interactionsLayerRef}
                className={cn(classes.handleInteractionsLayer, customClasses.handleInteractionsLayer)}
                type="button"
                onFocus={handleFocus}
                {...{ onClick }}
            />
        </div>
    );
};

export const ClickableTextField = ClickableTextFieldComponent;
