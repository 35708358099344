import React, { useMemo } from 'react';

import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { Paper } from '@material-ui/core';

import { TEMPLATE_ACTIONS } from './templates_actions';

import { styles } from './game_step_phone_actions_styles';

const useStyles = makeStyles(styles);

const GameStepPhoneActionsComponent = ({ templateNumber }) => {
    const classes = useStyles();
    const actions = useMemo(() => (TEMPLATE_ACTIONS[templateNumber] || {}), [templateNumber]);
    return (
        <div className={cn(classes.container)}>
            {actions.map(({ label, icon: Icon }) => (
                <Paper
                    key={`game_step_phone_action_${label}`}
                    classes={{
                        root: classes.paper
                    }}
                >
                    <Icon className={classes.icon} />
                    {label}
                </Paper>
            ))}
        </div>
    );
};

export const GameStepPhoneActions = GameStepPhoneActionsComponent;
