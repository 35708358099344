export const styles = (theme) => ({
    contentContainer: {
        width: 1,
        height: 'fit-content',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    content: {
        padding: theme.spacing(6, 8),
        width: '100%',
        maxWidth: '100vw'
    },
    '@media screen and (max-width: 750px)': {
        content: {
            padding: theme.spacing(3, 4)
        }
    },
    '@media screen and (max-width: 450px)': {
        content: {
            padding: theme.spacing(3, 2.5)
        }
    }
});
