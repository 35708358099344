import React from 'react';

import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { Divider, Typography } from '@material-ui/core';

import { styles } from './content_header_styles';

const useStyles = makeStyles(styles);

const ContentHeaderComponent = ({ title = 'Dashboard', subtitle, children, customClasses = {} }) => {
    const classes = useStyles();
    return (
        <div className={cn(classes.container, customClasses.container)}>
            <div className={cn(classes.header, customClasses.header)}>
                <div className={cn(classes.typographies, customClasses.typographies)}>
                    <Typography
                        component="div"
                        classes={{
                            root: cn(classes.title, customClasses.title)
                        }}
                    >
                        {title}
                    </Typography>
                    {subtitle && (
                        <Typography
                            classes={{
                                root: cn(classes.subtitle, customClasses.subtitle)
                            }}
                        >
                            {subtitle}
                        </Typography>
                    )}
                </div>
                {children && (
                    <div className={cn(classes.children, customClasses.children)}>
                        {children}
                    </div>
                )}
            </div>
            <Divider
                classes={{
                    root: classes.divider
                }}
            />
        </div>
    );
};

export const ContentHeader = ContentHeaderComponent;
