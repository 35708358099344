import { flex } from '../../../../../../../../utils/styles_utils';

const { center } = flex;

export const styles = (theme) => ({
    container: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end'
    },
    paper: {
        width: 'fit-content',
        height: 'fit-content',
        padding: theme.spacing(2),
        fontSize: 14,
        ...center,
        '&:not(:last-child)': {
            marginRight: theme.spacing(1)
        }
    },
    icon: {
        height: 16,
        width: 'auto',
        marginRight: theme.spacing(1.5)
    }
});
