export const CONTENT_TYPES_DATA = ({
    image: {
        value: 'image',
        name: 'Image'
    },
    video: {
        value: 'video',
        name: 'Vidéo'
    }
});
