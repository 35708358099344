import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Select,
    MenuItem,
    TextField
} from '@material-ui/core';

import { ROLE_DATA } from '../../../utils/role_data';

import { addDashboardUser } from '../../../actions/dashboard_users_actions';
import { useUserEmail } from '../../../state/selectors/user_selectors';

import { styles } from './add_dashboard_user_dialog_styles';

const useStyles = makeStyles(styles);

const AddUserDashboardDialogComponent = ({
    open,
    onClose
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const rolesEntries = useMemo(() => Object.entries(ROLE_DATA), [ROLE_DATA]);
    const [email, setEmail] = useState('');
    const [role, setRole] = useState(rolesEntries[0][0]);
    const currentUserEmail = useUserEmail();

    const handleEmailChange = useCallback((event) => {
        const { target: { value } } = event;
        setEmail(value);
    }, []);

    const handleRoleCange = useCallback((event) => {
        const { target: { value } } = event;
        setRole(value);
    }, []);

    const disabled = useMemo(() => !email || !role, [email, role]);

    const handleAddButtonClick = useCallback(() => {
        if (disabled) {
            return;
        }
        addDashboardUser(email, {
            allowedBy: currentUserEmail,
            allowedAt: new Date().getTime(),
            role
        })(dispatch).then(onClose);
    }, [disabled, email, role, onClose]);

    return (
        <Dialog
            {...{
                open,
                onClose
            }}
        >
            <DialogTitle>
                {'Ajouter un utilisateur ?'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {'Rensignez les informations nécessaire afin que cet utilisateur puisse se connecter et utiliser les fonctionnalités de ce dashboard.'}
                </DialogContentText>
                <div className={classes.fieldsContainer}>
                    <TextField
                        classes={{
                            root: cn(classes.field, classes.emailField)
                        }}
                        variant="outlined"
                        onChange={handleEmailChange}
                        label="Email"
                        placeholder="john@doe.com"
                        value={email}
                        type="email"
                    />
                    <Select
                        className={cn(classes.field, classes.roleField)}
                        value={role}
                        label="Role"
                        variant="outlined"
                        onChange={handleRoleCange}
                    >
                        {rolesEntries.map(([roleId, { name }]) => (
                            <MenuItem
                                key={`role_menu_item_${roleId}`}
                                value={roleId}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    onClick={onClose}
                >
                    {'Fermer'}
                </Button>
                <Button
                    color="primary"
                    size="small"
                    onClick={handleAddButtonClick}
                    {...{ disabled }}
                >
                    {'Ajouter'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const AddUserDashboardDialog = AddUserDashboardDialogComponent;
