export const styles = (theme) => ({
    form: {
        width: '100%',
        display: 'flex'
    },
    fieldsContainer: {
        flex: 1
    },
    field: {
        margin: theme.spacing(3, 0)
    },
    demoContainer: {
        width: '100%',
        maxWidth: 300,
        marginLeft: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    actions: {
        justifyContent: 'flex-end'
    },
    formSwitch: {
        marginBottom: theme.spacing(3)
    },
    circularProgress: {
        marginRight: theme.spacing(1.5)
    },
    '@media screen and (max-width: 850px)': {
        form: {
            flexDirection: 'column-reverse',
            alignItems: 'center'
        },
        demoContainer: {
            marginLeft: 0
        }
    }
});
