import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    List,
    ListItem,
    Menu,
    MenuItem
} from '@material-ui/core';

import { EditEntryDialog } from './edit_entry_dialog/edit_entry_dialog';

import { setHomeCardList } from '../../../../../actions/home_actions';
import { useCallbackOpen } from '../../../../../hooks/use_callback_open';

import { styles } from './edit_list_dialog_styles';

const useStyles = makeStyles(styles);

const EditListDialogComponent = ({ open, onClose, value: receivedValue, documentId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = useState(receivedValue);

    useEffect(() => {
        setValue(receivedValue);
    }, [receivedValue]);

    const addItem = useCallback(() => {
        setHomeCardList(documentId, [...value, `Entrée n° ${value.length + 1}`])(dispatch);
    }, [value]);

    const handleChange = useCallback(
        (index, newItemValue) => {
            const newValue = [...value];
            newValue[index] = newItemValue;
            setHomeCardList(documentId, newValue)(dispatch);
        },
        [value]
    );

    const handleDeletion = useCallback((index) => {
        const newValue = [...value];
        newValue.splice(index, 1);
        setHomeCardList(documentId, newValue)(dispatch);
    }, []);

    return (
        <Dialog {...{ open, onClose }}>
            <DialogTitle>{'Changer les élements de la liste ?'}</DialogTitle>
            <DialogContent>
                {value && Array.isArray(value) && (
                    <List>
                        {value.map((itemValue, index) => (
                            <Item
                                key={`edit_list_dialog_${index}`}
                                {...{ itemValue, handleChange, handleDeletion, index, classes }}
                            />
                        ))}
                    </List>
                )}
            </DialogContent>
            <DialogActions>
                <Button size={'small'} onClick={onClose}>
                    {'Fermer'}
                </Button>
                <Button size="small" color="primary" onClick={addItem}>
                    {'Ajouter une entrée'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Item = ({ itemValue, handleChange, handleDeletion, index, classes }) => {
    const anchorReference = useRef();
    const [openMenu, setMenuOpened, setMenuClosed] = useCallbackOpen();
    const [openEditEntryDialog, setEditEntryDialogOpened, setEditEntryDialogClosed] = useCallbackOpen();
    const handleItemChange = useCallback(
        (newItemValue) => {
            if (typeof handleChange === 'function') {
                handleChange(index, newItemValue);
            }
        },
        [index, handleChange]
    );

    const handleItemDeletion = useCallback(() => {
        if (typeof handleDeletion === 'function') {
            handleDeletion(index);
        }
    }, [index, handleDeletion]);
    return (
        <>
            <EditEntryDialog
                open={openEditEntryDialog}
                onClose={setEditEntryDialogClosed}
                value={itemValue}
                onSave={handleItemChange}
            />
            <Menu anchorEl={anchorReference.current} open={openMenu} onClose={setMenuClosed} keepMounted>
                <MenuItem onClick={setEditEntryDialogOpened}>{'Modifier'}</MenuItem>
                <MenuItem onClick={handleItemDeletion}>{'Supprimer'}</MenuItem>
            </Menu>
            <ListItem classes={{ root: classes.listItem }} ref={anchorReference} button onClick={setMenuOpened}>
                {itemValue}
            </ListItem>
        </>
    );
};

export const EditListDialog = EditListDialogComponent;
