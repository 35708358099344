import React, { useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { UrlUpload } from '../../url_upload/url_upload';

import { styles } from './url_upload_field_styles';

const useStyles = makeStyles(styles);

const UrlUploadFieldComponent = ({
    field,
    form,
    fileName,
    path = '/miscellaneous',
    cssSelector,
    variant = 'outlined',
    error,
    label
}) => {
    const classes = useStyles();

    const { name, value } = field;

    const handleChange = useCallback((url) => {
        form.setFieldTouched(name, true);
        form.setFieldValue(name, url);
    }, []);

    return (
        <UrlUpload
            id={cssSelector}
            onChange={handleChange}
            {...(error && {
                error: true,
                helperText: error
            })}
            {...{ name, fileName, value, path, variant, label }}
        />
    );
};

export const UrlUploadField = UrlUploadFieldComponent;
