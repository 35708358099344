import React from 'react';

import { RegularTextField } from '../regular_text_field/regular_text_field';

const NumberTextFieldComponent = (props) => (
    <RegularTextField
        {...props}
        type="number"
    />
);

export const NumberTextField = NumberTextFieldComponent;
