import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

import { styles } from './card_title_styles';

const useStyles = makeStyles(styles);

const CardTitleComponent = ({ children }) => {
    const classes = useStyles();
    return (
        <Typography
            classes={{
                root: classes.container
            }}
        >
            {children}
        </Typography>
    );
};

export const CardTitle = CardTitleComponent;
