import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listenToBadges } from '../../../actions/miscellaneous/badges_actions';

export const useBadges = () => {
    const badges = useSelector(
        ({ miscellaneous: { badges: inReducerBadges } }) => inReducerBadges
    );
    const hasReceivedBadges = useSelector(
        ({ miscellaneous: { hasReceivedBadges: inReducerHasReceivedBadges } }) =>
            inReducerHasReceivedBadges
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (hasReceivedBadges === false) {
            console.log('[useBadges] Listening to badges...');
            listenToBadges()(dispatch);
        }
    }, [hasReceivedBadges, dispatch]);

    return {
        badges,
        loading: hasReceivedBadges === null,
        error: hasReceivedBadges !== true && hasReceivedBadges
    };
};
