import { ObjectiveCard } from './generic_cards/objective_card/objective_card';
import { ListLengthCard } from './generic_cards/list_length_card/list_length_card';

export const HOME_CARDS_DATA = Object.freeze({
    publishedProjects: {
        documentId: 'published_projects',
        name: 'Projets publiés',
        component: ObjectiveCard,
        gridDimensions: {
            xs: 12,
            sm: 6,
            md: 4
        }
    },
    draftProjects: {
        documentId: 'draft_projects',
        name: 'Projets brouillons',
        component: ObjectiveCard,
        color: 'secondary',
        gridDimensions: {
            xs: 12,
            sm: 6,
            md: 4
        }
    },
    usersCount: {
        documentId: 'users_count',
        name: 'Nombre de joueurs',
        component: ObjectiveCard,
        gridDimensions: {
            xs: 12,
            sm: 6,
            md: 4
        }
    },
    citiesNumber: {
        documentId: 'cities_count',
        name: 'Nombre de villes',
        component: ListLengthCard,
        gridDimensions: {
            xs: 12,
            sm: 6,
            md: 4
        }
    }
});
