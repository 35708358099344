export const styles = ({
    paper: {
        width: '100%',
        overflowX: 'auto'
    },
    badgeImage: {
        height: 40,
        width: 40,
        objectFit: 'contain'
    },
    textCell: {
       minWidth: 350
    },
    actions: {
        display: 'flex',
        flexDirection: 'row'
    }
});
