import React from 'react';

import { RegularTextField } from '../regular_text_field/regular_text_field';

const MultilineTextFieldComponent = (props) => (
    <RegularTextField
        {...props}
        multiline
    />
);

export const MultilineTextField = MultilineTextFieldComponent;
