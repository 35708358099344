/* eslint no-unused-vars:0 */
import firebase from '../firebase';

import {
    AUTH_STATE_CHANGED_RECEIVED_USER,
    AUTH_STATE_CHANGED_NO_USER,
    LOGIN_USER_WITH_EMAIL_STARTED,
    LOG_OUT_USER,
    DATABASE_EDIT_SUBMIT,
    DATABASE_EDIT_FINISH,
    LISTEN_TO_DASHBOARD_USER_STARTED,
    LISTEN_TO_DASHBOARD_USER_RECEIVED_DATA,
    LISTEN_TO_DASHBOARD_USER_RECEIVED_ERROR
} from './types';

const auth = firebase.auth();
const database = firebase.firestore();

export const setAuthPersistence = () => () =>
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export const logOutUser = () => () => {
    auth.signOut();
    window.location.reload();
};

export const getIsDashboardUser = async (email) => {
    try {
        const document = await database.collection('dashboard_users').doc(email).get();
        if (!document.exists) {
            return false;
        }
        return true;
    } catch (error) {
        return false;
    }
};

export const listenToDashboardUser = (email) => (dispatch) => {
    dispatch({
        type: LISTEN_TO_DASHBOARD_USER_STARTED
    });
    return database.collection('dashboard_users').doc(email)
        .onSnapshot((snapshot) => {
            dispatch({
                type: LISTEN_TO_DASHBOARD_USER_RECEIVED_DATA,
                dashboardUser: snapshot.data()
            });
        }, (error) => dispatch({
            type: LISTEN_TO_DASHBOARD_USER_RECEIVED_ERROR,
            error
        }));
};

export const listenForAuthStateChange = () => (dispatch) => {
    auth.onAuthStateChanged(async (user) => {
        if (user) {
            const { email } = user;
            const isDashboardUser = await getIsDashboardUser(email);
            if (!isDashboardUser) {
                return logOutUser()();
            }
            console.log('[User Actions] Received uid.');
            dispatch(listenToDashboardUser(email));
            const { uid } = user;
            dispatch({
                type: AUTH_STATE_CHANGED_RECEIVED_USER,
                uid,
                email
            });
            return null;
        }
        console.log('[User Actions] Did not receive user.');
        return dispatch({
            type: AUTH_STATE_CHANGED_NO_USER
        });
    });
};

export const listenForRedirectResult = () => (dispatch) =>
    auth.getRedirectResult()
        .then(() => dispatch(listenForAuthStateChange()))
        .catch((error) => console.log('[User Actions] Error:', error));

export const loginWithProvider = (provider) => () => {
    if (!provider) {
        return null;
    }
    let newProvider = null;
    switch (provider) {
    case 'google': newProvider = new firebase.auth.GoogleAuthProvider(); break;
    case 'twitter': newProvider = new firebase.auth.TwitterAuthProvider(); break;
    case 'facebook': newProvider = new firebase.auth.FacebookAuthProvider(); break;
    default: newProvider = new auth.GithubAuthProvider();
    }
    return auth.signInWithRedirect(newProvider);
};
