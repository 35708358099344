import { DATABASE_EDIT_SUBMIT, DATABASE_EDIT_FINISH } from '../../actions/types';

const initialState = {
    isEditingDatabase: false
};

export default (state = initialState, action) => {
    switch (action.type) {
    case DATABASE_EDIT_SUBMIT:
        return {
            ...state,
            isEditingDatabase: true
        };

    case DATABASE_EDIT_FINISH:
        return {
            ...state,
            isEditingDatabase: false
        };

    default:
        return state;
    }
};
