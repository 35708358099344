export const STATUS_CHIP_DATA = Object.freeze({
    published: {
        label: 'Publié',
        color: 'primary'
    },
    draft: {
        label: 'Brouillon',
        color: 'secondary'
    }
});
