import React from 'react';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress } from '@material-ui/core';

import { ContentHeader } from '../../small_views/content_header/content_header';
import { StatusChip } from '../status_chip/status_chip';
import { ChangeStatusAction } from './change_status_action/change_status_action';
import { EditProjectCard } from './edit_project_card/edit_project_card';
import { ErrorTypography } from '../../small_views/error_typography/error_typography';

import { useDraftProject } from '../../../state/fetchers/projects/use_draft_projects';
import { useProject } from '../../../state/fetchers/projects/use_projects';

import { styles } from './edit_project_styles';

const useStyles = makeStyles(styles);

const useFetcher = (status) => {
    switch (status) {
    case 'draft':
        return useDraftProject;
    default:
        return useProject;
    }
};

const EditProjectComponent = () => {
    const classes = useStyles();
    const params = useParams();
    const { status, documentId } = params;
    const { loading, error, project, draftProject } = useFetcher(status)(documentId);
    return (
        <>
            <Header
                {...{
                    documentId,
                    status,
                    draftProject,
                    classes
                }}
            />
            <div className={classes.container}>
                <Content
                    {...{
                        loading,
                        error,
                        documentId,
                        status,
                        project,
                        draftProject
                    }}
                />
            </div>
        </>
    );
};

const Content = ({ loading, error, documentId, status, project, draftProject }) => {
    if (loading) {
        return <CircularProgress />;
    }
    if (error) {
        return <ErrorTypography {...{ error }} />;
    }
    if (project || draftProject) {
        return <EditProjectCard {...{ documentId, status, project, draftProject }} />;
    }
    return (
        <ErrorTypography
            error="Hum, ce projet n'a pas l'air disponible."
        />
    );
};

const Header = ({ documentId, status, draftProject, classes }) => (
    <ContentHeader
        title={(
            <div className={classes.contentHeaderTitle}>
                {'Editer un projet'}
                <div className={classes.divider} />
                <StatusChip
                    customClasses={{
                        root: classes.statusChip
                    }}
                    {...{ status }}
                />
            </div>
        )}
    >
        <ChangeStatusAction
            {...{
                documentId,
                status,
                draftProject
            }}
        />
    </ContentHeader>
);

export const EditProject = EditProjectComponent;
