import React, { forwardRef } from 'react';

import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { styles } from './editable_button_styles';

const useStyles = makeStyles(styles);

const EditableButtonComponent = forwardRef(({ children, className, ...other }, ref) => {
    const classes = useStyles();
    return (
        <button {...{ ref }} className={cn(classes.button, className)} type="button" {...other}>
            {children}
        </button>
    );
});

export const EditableButton = EditableButtonComponent;
