import React, { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { styles } from './file_handling_styles';

const useStyles = makeStyles(styles);

const FileHandlingComponent = forwardRef(({
    id,
    children,
    accept = 'image/*',
    multiple = true,
    type = 'file',
    ...other
}, ref) => {
    const classes = useStyles();
    return (
        <>
            <input
                className={classes.input}
                {...{ ref, id, accept, multiple, type }}
                {...other}
            />
            <label htmlFor={id}>
                {children}
            </label>
        </>
    );
});

export const FileHandling = FileHandlingComponent;
