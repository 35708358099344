import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    IconButton
} from '@material-ui/core';

import { Delete as DeleteIcon } from '@material-ui/icons';

import { GenericConfirmDialog } from '../../small_views/generic_confirm_dialog/generic_confirm_dialog';

import { deleteGameUser } from '../../../actions/game_users_actions';

import { styles } from './game_users_table_styles';

const useStyles = makeStyles(styles);

const GameUsersTableComponent = ({ gameUsers }) => {
    const classes = useStyles();
    return (
        <Paper
            classes={{
                root: classes.paper
            }}
        >
            <Table className={classes.table} aria-label="projects list">
                <TableHead>
                    <TableRow>
                        <TableCell>{'Pseudo'}</TableCell>
                        <TableCell>{'Email'}</TableCell>
                        <TableCell>{'ID'}</TableCell>
                        <TableCell>{'Code Postal'}</TableCell>
                        <TableCell>{'Points'}</TableCell>
                        <TableCell>{"Niveau actuel"}</TableCell>
                        <TableCell align="right">{'Actions'}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(gameUsers).map(([documentId, gameUser]) => (
                        <GameUserRow key={`game_user_row_${documentId}`} {...{ documentId, gameUser, classes }} />
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

const GameUserRow = ({ documentId, gameUser, classes }) => {
    const { pseudo: nickname, email, id, postalCode, points, levelRef } = gameUser;
    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {nickname}
            </TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>{id}</TableCell>
            <TableCell>{postalCode}</TableCell>
            <TableCell>{points}</TableCell>
            <TableCell>{levelRef}</TableCell>
            <TableCell align="right">
                <DeleteUserAction {...{ documentId }} />
            </TableCell>
        </TableRow>
    );
};

const DeleteUserAction = ({ documentId }) => {
    const dispatch = useDispatch();
    const [openConfirmDialog, setConfirmDialogOpenState] = useState(false);
    const setConfirmDialogOpened = useCallback(() => setConfirmDialogOpenState(true));
    const setConfirmDialogClosed = useCallback(() => setConfirmDialogOpenState(false));

    const handleConfirm = useCallback(() => deleteGameUser(documentId)(dispatch), [documentId]);

    return (
        <>
            <GenericConfirmDialog
                open={openConfirmDialog}
                onConfirm={handleConfirm}
                onClose={setConfirmDialogClosed}
            />
            <Tooltip title="Supprimer cet utilisateur">
                <IconButton onClick={setConfirmDialogOpened}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </>
    );
};

export const GameUsersTable = GameUsersTableComponent;
