import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';

import { Paper } from '@material-ui/core';

import { styles } from './game_step_phone_content_styles';

const useStyles = makeStyles(styles);

const GameStepPhoneContentComponent = ({ contentType, content }) => {
    const classes = useStyles();
    return (
        <Paper
            classes={{
                root: classes.container
            }}
        >
            <Content
                {...{
                    contentType,
                    content,
                    classes
                }}
            />
        </Paper>
    );
};

const Content = ({ contentType, content, classes }) => {
    if (!content) {
        return null;
    }
    if (contentType === 'image') {
        return (
            <img className={classes.contentImage} src={content} alt={content} />
        );
    }
    if (contentType === 'video') {
        return (
            <ReactPlayer
                url={content}
            />
        );
    }
    return null;
};

export const GameStepPhoneContent = GameStepPhoneContentComponent;
