import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import { setPublishedProjectAsDraft } from '../../../../../actions/projects_actions';

import { styles } from './set_as_draft_action_styles';

const useStyles = makeStyles(styles);

const SetAsDraftActionComponent = ({ documentId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const handleClick = useCallback(() => {
        setPublishedProjectAsDraft(documentId)(dispatch)
            .then(() => history.push(`/edit-project/draft/${documentId}/general`));
    }, [documentId, dispatch, history]);
    return (
        <Button
            color="secondary"
            variant="contained"
            onClick={handleClick}
        >
            {'Placer dans les brouillons'}
        </Button>
    );
}

export const SetAsDraftAction = SetAsDraftActionComponent;
