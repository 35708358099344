import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    BrowserRouter as Router
} from 'react-router-dom';

import { makeStyles, ThemeProvider } from '@material-ui/core/styles';

import { Routes } from '../routes/routes';

import {
    setAuthPersistence,
    listenForRedirectResult
} from '../../actions/user_actions';

import { theme } from '../../utils/theme';

import { styles } from './app_styles';

const useStyles = makeStyles(styles);

export const AppComponent = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        listenForRedirectResult()(dispatch);
        setAuthPersistence()(dispatch);
    }, [dispatch]);

    return (
        <ThemeProvider {...{ theme }}>
            <Router>
                <div className={classes.container}>
                    <Routes />
                </div>
            </Router>
        </ThemeProvider>
    );
};

export const App = AppComponent;
