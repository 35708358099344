import React, { memo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Chip } from '@material-ui/core';

import { STATUS_CHIP_DATA } from './status_chip_data';

import { styles } from './status_chip_styles';

const useStyles = makeStyles(styles);

const StatusChipComponent = memo(({ status, customClasses }) => {
    const classes = useStyles();
    const { label, color } = STATUS_CHIP_DATA[status];
    return (
        <Chip
            classes={{
                ...customClasses
            }}
            {...{
                label,
                color
            }}
        />
    );
});

export const StatusChip = StatusChipComponent;
