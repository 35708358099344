import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { Portal } from '@material-ui/core';

import { ReactComponent as PhoneVector } from '../../../assets/iphone.svg';

import { styles } from './phone_styles';

const useStyles = makeStyles(styles);

const PhoneComponent = ({ children, customClasses = {}, ...other }) => {
    const classes = useStyles();
    const [contentElement, setContentElement] = useState();
    useEffect(() => {
        setContentElement(document.getElementById('phone-content'));
    }, []);
    return (
        <>
            <PhoneVector
                className={cn(classes.phone, customClasses.phone)}
                {...other}
            />
            {children && (
                <Portal
                    container={contentElement}
                >
                    {children}
                </Portal>
            )}
        </>
    );
};

export const Phone = PhoneComponent;
