export const styles = (theme) => ({
    container: {
        width: '100%',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    fields: {
        width: '100%'
    },
    formControl: {
        minWidth: 200
    },
    select: {
        paddingRight: theme.spacing(5)
    },
    divider: {
        width: '100%',
        maxWidth: 300,
        margin: theme.spacing(2.5, 0, 5),
        backgroundColor: 'rgba(0, 0, 0, 0.12)'
    }
});
