// Users Actions

export const AUTH_STATE_CHANGED_RECEIVED_USER = 'AUTH_STATE_CHANGED_RECEIVED_USER';
export const AUTH_STATE_CHANGED_NO_USER = 'AUTH_STATE_CHANGED_NO_USER';

export const LOGIN_USER_WITH_EMAIL_STARTED = 'LOGIN_USER_WITH_EMAIL_STARTED';
export const LOGIN_USER_WITH_EMAIL_RECEIVED_USER = 'LOGIN_USER_WITH_EMAIL_RECEIVED_USER';
export const LOGIN_USER_WITH_EMAIL_ERROR = 'LOGIN_USER_WITH_EMAIL_ERROR';

export const SIGNUP_USER_WITH_EMAIL_STARTED = 'SIGNUP_USER_WITH_EMAIL_STARTED';

export const LOG_OUT_USER = 'LOG_OUT_USER';

export const LISTEN_TO_DASHBOARD_USER_STARTED = 'LISTEN_TO_USER_ROLE_STARTED';
export const LISTEN_TO_DASHBOARD_USER_RECEIVED_DATA = 'LISTEN_TO_USER_ROLE_RECEIVED_DATA';
export const LISTEN_TO_DASHBOARD_USER_RECEIVED_ERROR = 'LISTEN_TO_USER_ROLE_RECEIVED_ERROR';

// Dashboard Users Actions

export const LISTEN_TO_DASHBOARD_USERS_STARTED = 'LISTEN_TO_DASHBOARD_USERS_STARTED';
export const LISTEN_TO_DASHBOARD_USERS_RECEIVED_DATA = 'LISTEN_TO_DASHBOARD_USERS_RECEIVED_DATA';
export const LISTEN_TO_DASHBOARD_USERS_RECEIVED_ERROR = 'LISTEN_TO_DASHBOARD_USERS_RECEIVED_ERROR';

// Utils Actions

export const SET_WIDTH = 'SET_WIDTH';

export const DATABASE_EDIT_SUBMIT = 'DATABASE_EDIT_SUBMIT';
export const DATABASE_EDIT_FINISH = 'DATABASE_EDIT_FINISH';

// Miscellaneous Actions

export const LISTEN_TO_BADGES_STARTED = 'LISTEN_TO_BADGES_STARTED';
export const LISTEN_TO_BADGES_RECEIVED_DATA = 'LISTEN_TO_BADGES_RECEIVED_DATA';
export const LISTEN_TO_BADGES_RECEIVED_ERROR = 'LISTEN_TO_BADGES_RECEIVED_ERROR';

// Game Users Actions

export const LISTEN_TO_GAME_USERS_STARTED = 'LISTEN_TO_GAME_USERS_STARTED';
export const LISTEN_TO_GAME_USERS_RECEIVED_DATA = 'LISTEN_TO_GAME_USERS_RECEIVED_DATA';
export const LISTEN_TO_GAME_USERS_RECEIVED_ERROR = 'LISTEN_TO_GAME_USERS_RECEIVED_ERROR';

// Projects Actions

export const LISTEN_TO_PROJECTS_STARTED = 'LISTEN_TO_PROJECTS_STARTED';
export const LISTEN_TO_PROJECTS_RECEIVED_DATA = 'LISTEN_TO_PROJECTS_RECEIVED_DATA';
export const LISTEN_TO_PROJECTS_RECEIVED_ERROR = 'LISTEN_TO_PROJECTS_RECEIVED_ERROR';

export const LISTEN_TO_PROJECT_STARTED = 'LISTEN_TO_PROJECT_STARTED';
export const LISTEN_TO_PROJECT_RECEIVED_DATA = 'LISTEN_TO_PROJECT_RECEIVED_DATA';
export const LISTEN_TO_PROJECT_RECEIVED_ERROR = 'LISTEN_TO_PROJECT_RECEIVED_ERROR';

export const LISTEN_TO_DRAFT_PROJECTS_STARTED = 'LISTEN_TO_DRAFT_PROJECTS_STARTED';
export const LISTEN_TO_DRAFT_PROJECTS_RECEIVED_DATA = 'LISTEN_TO_DRAFT_PROJECTS_RECEIVED_DATA';
export const LISTEN_TO_DRAFT_PROJECTS_RECEIVED_ERROR = 'LISTEN_TO_DRAFT_PROJECTS_RECEIVED_ERROR';

export const LISTEN_TO_DRAFT_PROJECT_STARTED = 'LISTEN_TO_DRAFT_PROJECT_STARTED';
export const LISTEN_TO_DRAFT_PROJECT_RECEIVED_DATA = 'LISTEN_TO_DRAFT_PROJECT_RECEIVED_DATA';
export const LISTEN_TO_DRAFT_PROJECT_RECEIVED_ERROR = 'LISTEN_TO_DRAFT_PROJECT_RECEIVED_ERROR';

export const LISTEN_TO_GAME_STARTED = 'LISTEN_TO_GAME_STARTED';
export const LISTEN_TO_GAME_RECEIVED_DATA = 'LISTEN_TO_GAME_RECEIVED_DATA';
export const LISTEN_TO_GAME_RECEIVED_ERROR = 'LISTEN_TO_GAME_RECEIVED_ERROR';

// Home Actions

export const LISTEN_TO_HOME_CARD_STARTED = 'LISTEN_TO_HOME_CARD_STARTED';
export const LISTEN_TO_HOME_CARD_RECEIVED_DATA = 'LISTEN_TO_HOME_CARD_RECEIVED_DATA';
export const LISTEN_TO_HOME_CARD_RECEIVED_ERROR = 'LISTEN_TO_HOME_CARD_RECEIVED_ERROR';
