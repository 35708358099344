import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { Button, CircularProgress, TextField } from '@material-ui/core';

import { FileHandling } from '../file_handling/file_handling';

import { uploadFileToStorage } from '../../../actions/miscellaneous/miscellanous_actions';

import { styles } from './url_upload_styles';

const useStyles = makeStyles(styles);

const UrlUploadComponent = ({
    id,
    fileName: receivedFileName,
    value: receivedValue,
    path,
    onChange,
    fileHandlingProps,
    customClasses = {},
    ...other
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isUploading, setIsUploading] = useState(false);
    const [localValue, setLocalValue] = useState('');

    useEffect(() => setLocalValue(receivedValue), [receivedValue]);

    const handleChange = useCallback((event) => {
        const { target: { value } } = event;
        setLocalValue(value);
        if (typeof onChange === 'function') {
            onChange(value);
        }
    });

    const handleFileChange = useCallback((event) => {
        const { target: { files } } = event;
        const [firstFile] = files;
        if (!firstFile) {
            return;
        }
        const { name: fileName } = firstFile;
        const extentionRegex = /\.[^/.]+$/;
        const extention = fileName.match(extentionRegex);
        setIsUploading(true);
        uploadFileToStorage(firstFile, receivedFileName && `${receivedFileName}${extention}`, path)(dispatch)
            .then(onChange)
            .finally(() => setIsUploading(false));
    }, []);

    return (
        <div className={cn(classes.container, customClasses.container)}>
            <TextField
                fullWidth
                classes={{
                    root: classes.textField
                }}
                value={localValue}
                onChange={handleChange}
                {...other}
            />
            <FileHandling
                id={`file-upload-button-${id}`}
                accept="*"
                multiple={false}
                onChange={handleFileChange}
                {...fileHandlingProps}
            >
                <Button
                    component="span"
                    classes={{
                        root: classes.uploadButton
                    }}
                    variant="outlined"
                >
                    {isUploading && (
                        <CircularProgress
                            classes={{
                                root: classes.circularProgress
                            }}
                            size={15}
                        />
                    )}
                    {'Upload'}
                </Button>
            </FileHandling>
        </div>
    );
};

export const UrlUpload = UrlUploadComponent;
