export const styles = (theme) => ({
    overlayImagesFieldsContainer: {
        margin: theme.spacing(2, 0),
        paddingLeft: theme.spacing(5),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        '&::before': {
            position: 'absolute',
            top: 0,
            left: theme.spacing(2),
            height: '100%',
            width: 1,
            backgroundColor: theme.palette.grey[500],
            content: "''"
        }
    },
    overlayImageField: {
        margin: theme.spacing(2, 0),
        display: 'flex',
        alignItems: 'center'
    },
    urlUpload: {
        flex: 1
    },
    iconButton: {
        marginLeft: theme.spacing(2)
    },
    withMarginButton: {
        marginTop: theme.spacing(2)
    }
});
