import { STRING_FIELDS } from './game_step_templates_fields/string_fields';
import { NUMERIC_FIELDS } from './game_step_templates_fields/numeric_fields';
import { LOCATION_FIELDS } from './game_step_templates_fields/location_fields';
import { OVERLAY_IMAGES_FIELDS } from './game_step_templates_fields/overlay_images_fields';

export const GAME_STEP_TEMPLATES = Object.freeze({
    string: {
        name: 'Mot de passe',
        templateNumber: 1,
        fields: STRING_FIELDS
    },
    numeric: {
        name: 'Code numérique',
        templateNumber: 2,
        fields: NUMERIC_FIELDS
    },
    location: {
        name: 'Localisation',
        templateNumber: 3,
        fields: LOCATION_FIELDS
    },
    overlayImages: {
        name: 'Images superposées',
        templateNumber: 4,
        fields: OVERLAY_IMAGES_FIELDS
    },
    unknown: {
        name: 'Inconnu',
        templateNumber: 5
    }
});
