import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AnonymousRoutes } from './anonymous_routes/anonymous_routes';
import { LoggedRoutes } from './logged_routes/logged_routes';

import { useHasReceivedUid } from '../../state/selectors/user_selectors';

const RoutesComponent = () => {
    const hasReceivedUid = useHasReceivedUid();
    const { pathname } = useLocation();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    if (!hasReceivedUid) {
        return (
            <AnonymousRoutes />
        );
    }
    return <LoggedRoutes />;
};

export const Routes = RoutesComponent;
