import { withCustomHorizontalScrollbar } from '../../../../../utils/styles_utils';

export const styles = ({
    stepper: {
        width: '100%',
        maxWidth: '90%',
        overflow: 'auto',
        ...withCustomHorizontalScrollbar()
    },
    step: {
        minWidth: 95
    }
});
