import React, { useCallback } from 'react';

import { ClickableTextField } from '../../clickable_text_field/clickable_text_field';
import { SelectBadgeDialog } from '../../../badges/select_badge_dialog/select_badge_dialog';

import { useCallbackOpen } from '../../../../hooks/use_callback_open';

const SelectBadgeFieldComponent = ({
    field,
    error,
    form,
    label,
    variant = 'outlined'
}) => {
    const [openDialog, setDialogOpened, setDialogClosed] = useCallbackOpen();
    const { name, value, onBlur } = field;
    const { setFieldTouched, setFieldValue } = form;

    const handleSelect = useCallback((selectedBadgeId) => {
        setFieldValue(name, selectedBadgeId);
        setFieldTouched(name, true);
        setDialogClosed();
    }, [name, setFieldTouched, setFieldValue]);

    return (
        <>
            <SelectBadgeDialog
                open={openDialog}
                onClose={setDialogClosed}
                onSelect={handleSelect}
            />
            <ClickableTextField
                fullWidth
                onClick={setDialogOpened}
                onFocus={setDialogOpened}
                {...{ label, variant, value, onBlur }}
                {...error && {
                    error: true,
                    helperText: error
                }}
            />
        </>
    );
};

export const SelectBadgeField = SelectBadgeFieldComponent;
