import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField
} from '@material-ui/core';

import { setHomeCardObjective } from '../../../../../actions/home_actions';

const EditObjectiveDialogComponent = ({ open, onClose, objective, documentId }) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState(objective);

    console.log({ value, objective });

    const disabled = useMemo(() => !value || value === objective, [value, objective]);

    useEffect(() => {
        setValue(objective);
    }, [objective]);

    const handleChange = useCallback((event) => {
        const {
            target: { value: newValue }
        } = event;
        setValue(parseInt(newValue, 10));
    }, []);

    const handleSave = useCallback(() => {
        if (disabled) {
            return;
        }
        setHomeCardObjective(documentId, value)(dispatch)
            .then(onClose);
    }, [documentId, disabled, value, onClose]);

    return (
        <Dialog {...{ open, onClose }}>
            <DialogTitle>{"Changez l'objectif ?"}</DialogTitle>
            <DialogContent>
                <DialogContentText>{"Pour changer l'objectif, merci d'indiquer un nombre."}</DialogContentText>
                <TextField fullWidth type="number" variant="outlined" onChange={handleChange} {...{ value }} />
            </DialogContent>
            <DialogActions>
                <Button size={'small'} onClick={onClose}>
                    {'Fermer'}
                </Button>
                <Button size="small" color="primary" onClick={handleSave} {...{ disabled }}>
                    {'Sauvegarder'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const EditObjectiveDialog = EditObjectiveDialogComponent;
