import {
    Check as CheckIcon,
    CameraAlt as CameraIcon,
    LocationOn as LocationIcon
} from '@material-ui/icons';

const CAMERA = Object.freeze({
    label: 'Caméra',
    icon: CameraIcon
});

const VALIDATE = Object.freeze({
    label: 'Valider',
    icon: CheckIcon
});

const LOCATION = Object.freeze({
    label: 'Position',
    icon: LocationIcon
});

export const TEMPLATE_ACTIONS = Object.freeze({
    1: [VALIDATE],
    2: [VALIDATE],
    3: [LOCATION],
    4: [CAMERA, VALIDATE]
});
