import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listenToGameUsers } from '../../../actions/game_users_actions';

export const useGameUsers = () => {
    const gameUsers = useSelector(
        ({ gameUsers: { gameUsers: inReducerGameUsers } }) => inReducerGameUsers
    );
    const hasReceivedGameUsers = useSelector(
        ({ gameUsers: { hasReceivedGameUsers: inReducerHasReceivedGameUsers } }) =>
            inReducerHasReceivedGameUsers
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (hasReceivedGameUsers === false) {
            console.log('[useGameUsers] Listening to game users...');
            listenToGameUsers()(dispatch);
        }
    }, [hasReceivedGameUsers, dispatch]);

    return {
        gameUsers,
        loading: hasReceivedGameUsers === null,
        error: hasReceivedGameUsers !== true && hasReceivedGameUsers
    };
};
