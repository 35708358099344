import React, { createElement, memo, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Formik, FieldArray, Form, Field } from 'formik';
import { pick } from 'lodash';

import { Button, CircularProgress, CardActions, FormGroup, FormControlLabel, Switch } from '@material-ui/core';

import { ErrorTypography } from '../../../../../../small_views/error_typography/error_typography';
import { GameStepPhone } from '../game_step_phone/game_step_phone';

import { GAME_STEP_TEMPLATES } from '../game_step_templates';

import { updateOrCreateGameStep } from '../../../../../../../actions/projects_actions';

import {
    generateInitialValues,
    generateValidationSchema,
    generateFinalValues
} from '../../../../../../../utils/formik_tools';

import { styles } from './game_step_form_styles';

const useStyles = makeStyles(styles);

const GameStepFormComponent = ({
    documentId,
    projectDocumentId,
    status,
    template,
    gameStep,
    gameRefs,
    baseProjectNames,
    currentGameStepIndex
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { templateNumber, fields } = template;

    const initialValues = useMemo(() => generateInitialValues(fields, gameStep), [fields]);
    console.log({ initialValues });
    const validationSchema = useMemo(() => generateValidationSchema(fields), [fields]);

    const [saved, setSaved] = useState(false);
    const handleSubmit = useCallback(
        (values, actions) => {
            const nextStepRef = gameRefs && gameRefs[currentGameStepIndex] && gameRefs[currentGameStepIndex].id;
            const finalValues = generateFinalValues(fields, values, (gameStep || {}), false, { documentId, projectDocumentId });
            setSaved(null);
            return updateOrCreateGameStep(
                documentId,
                projectDocumentId,
                currentGameStepIndex,
                template.templateNumber,
                finalValues,
                nextStepRef
            )(dispatch).then(() => {
                setSaved(true);
                actions.setSubmitting(false);
                if (nextStepRef) {
                    history.push(`/edit-project/${status}/${projectDocumentId}/game-steps/${currentGameStepIndex + 1}`)
                }
            });
        },
        [fields, gameStep, documentId, projectDocumentId, status, currentGameStepIndex, gameRefs, dispatch]
    );

    return (
        <Formik
            {...{
                initialValues,
                validationSchema
            }}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmit}
        >
            {(bag) => {
                const { values, errors } = bag;
                return (
                    <Form className={classes.form}>
                        <div className={classes.fieldsContainer}>
                            {fields
                                && Object.values(fields).map((field) => (
                                    <div key={`game_step_${projectDocumentId}_${field.path}`} className={classes.field}>
                                        <StepField
                                            step={`${projectDocumentId}_${documentId}`}
                                            {...{
                                                documentId,
                                                projectDocumentId,
                                                field,
                                                errors,
                                                values
                                            }}
                                        />
                                    </div>
                                ))}
                            <CardActions
                                classes={{
                                    root: classes.actions
                                }}
                            >
                                <SaveButton {...{ saved, classes }} />
                            </CardActions>
                        </div>
                        <div className={classes.demoContainer}>
                            <WithFinalValuesPhone
                                {...{
                                    values,
                                    fields,
                                    gameStep,
                                    documentId,
                                    baseProjectNames,
                                    templateNumber,
                                    classes
                                }}
                            />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

const WithFinalValuesPhone = ({
    values,
    fields,
    gameStep = {},
    documentId,
    baseProjectNames,
    templateNumber,
    classes
}) => {
    const finalValues = useMemo(() => generateFinalValues(fields, values, gameStep, false, { documentId }), [
        values,
        fields,
        gameStep,
        documentId
    ]);
    const [showEnglish, setShowEnglish] = useState(false);
    const handleSwitchChange = useCallback((event) => setShowEnglish(event.target.checked), []);
    return (
        <>
            <FormGroup row classes={{ root: classes.formSwitch }}>
                <FormControlLabel
                    control={(
                        <Switch
                            color="primary"
                            checked={showEnglish}
                            value="showEnglish"
                            onChange={handleSwitchChange}
                        />
                    )}
                    label={'Version anglaise'}
                />
            </FormGroup>
            <GameStepPhone
                values={finalValues}
                language={showEnglish ? 'en' : 'fr'}
                {...{ baseProjectNames, templateNumber }}
            />
        </>
    );
};

const StepField = memo(({ step, field, documentId, projectDocumentId, values, errors }) => {
    const sanitizedName = field.path && field.path.replace(/\./g, '_');
    const { type } = field;
    const propsToPass = {
        step,
        documentId,
        projectDocumentId,
        name: sanitizedName,
        error: errors[sanitizedName],
        cssSelector: field.path && field.path.replace(/\./g, '-'),
        label: field.label,
        type: field.type,
        ...pick(field, ['placeholder', 'getData', 'defaultValue'])
    };
    if (type === 'array') {
        return (
            <FieldArray
                name={sanitizedName}
                validateOnChange={false}
                render={(arrayHelpers) =>
                    createElement(field.component, {
                        arrayHelpers,
                        values: values[sanitizedName] || [],
                        ...propsToPass
                    })}
            />
        );
    }
    return <Field component={field.component} {...propsToPass} />;
});

const WithTemplateForm = ({ templateId, ...other }) => {
    const template = useMemo(() => GAME_STEP_TEMPLATES[templateId], [templateId]);
    if (!template || !template.fields) {
        return <ErrorTypography error="Le template sélectionné n'est pas valide." />;
    }
    return (
        <GameStepFormComponent
            {...{
                template
            }}
            {...other}
        />
    );
};

const SaveButton = ({ saved, classes }) => (
    <Button color={saved ? 'primary' : 'secondary'} type="submit">
        {saved === null && (
            <CircularProgress
                color="secondary"
                classes={{
                    root: classes.circularProgress
                }}
                size={15}
            />
        )}
        <ButtonLabel {...{ saved }} />
    </Button>
);

const ButtonLabel = ({ saved }) => {
    if (saved === null) {
        return 'Sauvegarde en cours';
    }
    if (saved === false) {
        return 'Sauvegarder';
    }
    return 'Sauvegardé !';
};

export const GameStepForm = WithTemplateForm;
