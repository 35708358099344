import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import { GenericConfirmDialog } from '../../../../small_views/generic_confirm_dialog/generic_confirm_dialog';

import { useCallbackOpen } from '../../../../../hooks/use_callback_open';

import { setDraftProjectAsPublished } from '../../../../../actions/projects_actions';

import { styles } from './set_as_published_action_styles';

const useStyles = makeStyles(styles);

const SetAsPublishedActionComponent = ({ documentId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [openConfirmDialog, setConfirmDialogOpened, setConfirmDialogClosed] = useCallbackOpen();

    const handleConfirm = useCallback(() => {
        setDraftProjectAsPublished(documentId)(dispatch).then(() =>
            history.push(`/edit-project/published/${documentId}/general`));
    }, [documentId, dispatch, history]);
    return (
        <>
            <GenericConfirmDialog open={openConfirmDialog} onClose={setConfirmDialogClosed} onConfirm={handleConfirm} content="Vous êtes sur le point de publier un projet. Ce dernier deviendra alors accessible à tous les utilisateurs de l'application. Ne publiez un scénario que si vous avez vérifié l'intégrité des données en amont." />
            <Button color="primary" variant="contained" onClick={setConfirmDialogOpened}>
                {'Publier le projet'}
            </Button>
        </>
    );
};

export const SetAsPublishedAction = SetAsPublishedActionComponent;
