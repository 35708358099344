export const styles = (theme) => ({
    container: {
        width: '100%',
        marginBottom: theme.spacing(6)
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        '& > *': {
            marginBottom: theme.spacing(1)
        }
    },
    typographies: {
        display: 'flex',
        alignItems: 'baseline',
        flexWrap: 'wrap',
        marginRight: theme.spacing(4)
    },
    title: {
        fontSize: 8 * 4,
        fontWeight: 500,
        marginRight: theme.spacing(1.5)
    },
    subtitle: {
        fontSize: 8 * 2
    },
    divider: {
        backgroundColor: 'rgba(0, 0, 0, 0.12)'
    }
});
