import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Paper } from '@material-ui/core';

import { styles } from './game_step_phone_answer_styles';

const useStyles = makeStyles(styles);

const GameStepPhoneAnswerComponent = ({ templateNumber, children }) => {
    const classes = useStyles();
    const isLocationInput = useMemo(() => templateNumber === 3, [templateNumber]);
    if (isLocationInput) {
        return null;
    }
    return (
        <div className={classes.container}>
            <Paper
                classes={{
                    root: classes.paper
                }}
            >
                {children}
            </Paper>
        </div>
    );
};

export const GameStepPhoneAnswer = GameStepPhoneAnswerComponent;
