export const styles = ({
    progressContainer: {
        marginTop: 16
    },
    progressRoot: {
        height: 6,
        borderRadius: 2
    },
    progressTypography: {
        display: 'flex',
        fontSize: 28,
        marginBottom: 8
    }
});
