import React, { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    IconButton,
    Menu,
    MenuItem
} from '@material-ui/core';

import { Delete as DeleteIcon } from '@material-ui/icons';

import { GenericConfirmDialog } from '../../small_views/generic_confirm_dialog/generic_confirm_dialog';
import { EditableButton } from '../../small_views/editable_button/editable_button';

import { ROLE_DATA } from '../../../utils/role_data';

import { useUserEmail } from '../../../state/selectors/user_selectors';

import { setDashboardUserRole, deleteDashboardUser } from '../../../actions/dashboard_users_actions';

import { styles } from './dashboard_users_table_styles';

const useStyles = makeStyles(styles);

const DashboardUsersTableComponent = ({ dashboardUsers }) => {
    const classes = useStyles();
    return (
        <Paper
            classes={{
                root: classes.paper
            }}
        >
            <Table className={classes.table} aria-label="projects list">
                <TableHead>
                    <TableRow>
                        <TableCell>{'Email'}</TableCell>
                        <TableCell>{'Ajouté par'}</TableCell>
                        <TableCell>{"Date d'ajout"}</TableCell>
                        <TableCell>{'Role'}</TableCell>
                        <TableCell align="right">{'Actions'}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(dashboardUsers).map(([email, dashboardUser]) => (
                        <DashboardUserRow key={`dashboard_user_row_${email}`} {...{ email, dashboardUser, classes }} />
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

const DashboardUserRow = ({ email, dashboardUser, classes }) => {
    const { role, allowedBy, allowedAt } = dashboardUser;
    const currentUserEmail = useUserEmail();
    const isCurrentUser = useMemo(() => email === currentUserEmail, [email, currentUserEmail]);
    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {email}
            </TableCell>
            <TableCell>{allowedBy}</TableCell>
            <TableCell>{moment(allowedAt).format('DD/MM/YYYY [à] HH[h]mm')}</TableCell>
            <TableCell>
                <RoleCell {...{ email, role, isCurrentUser, classes }} />
            </TableCell>
            <TableCell align="right">{!isCurrentUser && <DeleteUserAction {...{ email }} />}</TableCell>
        </TableRow>
    );
};

const RoleCell = ({ email, role, isCurrentUser, classes }) => {
    const roleAnchorReference = useRef();
    const [openRoleMenu, setRoleMenuOpenState] = useState(false);

    const setRoleMenuOpened = useCallback(() => setRoleMenuOpenState(true), []);
    const setRoleMenuClosed = useCallback(() => setRoleMenuOpenState(false), []);

    const handleClick = useCallback(() => {
        if (isCurrentUser) {
            return;
        }
        setRoleMenuOpened();
    }, [isCurrentUser]);

    const Component = isCurrentUser ? 'button' : EditableButton;

    return (
        <>
            <Menu anchorEl={roleAnchorReference.current} open={openRoleMenu} onClose={setRoleMenuClosed}>
                {Object.entries(ROLE_DATA).map(([roleId, { name }]) => (
                    <RoleMenuItem key={`role_menu_${roleId}`} {...{ email, roleId, name, setRoleMenuClosed }} />
                ))}
            </Menu>
            <Component
                type="button"
                ref={roleAnchorReference}
                onClick={handleClick}
                {...(isCurrentUser && {
                    className: classes.disabledRole
                })}
            >
                {ROLE_DATA[role].name}
            </Component>
        </>
    );
};

const RoleMenuItem = forwardRef(({ email, roleId, name, setRoleMenuClosed }, ref) => {
    const dispatch = useDispatch();
    const handleClick = useCallback(() => {
        setDashboardUserRole(email, roleId)(dispatch).then(setRoleMenuClosed);
    }, [email, roleId]);
    return (
        <MenuItem {...{ ref }} onClick={handleClick}>
            {name}
        </MenuItem>
    );
});

const DeleteUserAction = ({ email }) => {
    const dispatch = useDispatch();
    const [openConfirmDialog, setConfirmDialogOpenState] = useState(false);
    const setConfirmDialogOpened = useCallback(() => setConfirmDialogOpenState(true));
    const setConfirmDialogClosed = useCallback(() => setConfirmDialogOpenState(false));

    const handleConfirm = useCallback(() => {
        deleteDashboardUser(email)(dispatch);
    }, [email]);

    return (
        <>
            <GenericConfirmDialog open={openConfirmDialog} onConfirm={handleConfirm} onClose={setConfirmDialogClosed} />
            <Tooltip title="Supprimer cet utilisateur">
                <IconButton onClick={setConfirmDialogOpened}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </>
    );
};

export const DashboardUsersTable = DashboardUsersTableComponent;
