import React from 'react';

import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { styles } from './game_step_phone_title_styles';

const useStyles = makeStyles(styles);

const GameStepPhoneTitleComponent = ({ children, customClasses = {} }) => {
    const classes = useStyles();
    return (
        <div
            className={cn(classes.container, customClasses.container)}
        >
            {children}
        </div>
    );
};

export const GameStepPhoneTitle = GameStepPhoneTitleComponent;
