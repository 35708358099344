import React, { useCallback } from 'react';

import { TextField } from '@material-ui/core';

const RegularTextFieldComponent = ({
    classes,
    field,
    step,
    label,
    cssSelector,
    placeholder,
    error,
    multiline,
    form,
    type,
    variant = 'outlined'
}) => {
    const { name, value, onBlur } = field;

    const onChange = useCallback(
        (event) => {
            const { target: { value: newValue } } = event;
            let finalValue = null;
            if (['number', 'float'].some((i) => type === i)) {
                if (!newValue) {
                    finalValue = '';
                } else {
                    finalValue = parseFloat(newValue, 10);
                }
            } else {
                finalValue = newValue;
            }
            form.setFieldValue(name, finalValue);
            form.setFieldTouched(name, true);
        },
        [form, type]
    );

    return (
        <TextField
            id={`${step}_form_${cssSelector}`}
            fullWidth
            {...error && {
                error: true,
                helperText: error
            }}
            {...multiline && {
                rows: 4
            }}
            {...{
                classes,
                onChange,
                multiline,
                label,
                onBlur,
                variant,
                placeholder,
                type,
                value
            }}
        />
    );
};

export const RegularTextField = RegularTextFieldComponent;
