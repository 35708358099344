export const styles = (theme) => ({
    container: {
        minHeight: 15,
        fontSize: 14,
        color: '#fff',
        fill: 'currentColor',
        marginTop: theme.spacing(3)
    },
    divider: {
        width: 150,
        marginTop: theme.spacing(1.5)
    }
});
