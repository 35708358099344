import {
    LISTEN_TO_BADGES_STARTED,
    LISTEN_TO_BADGES_RECEIVED_DATA,
    LISTEN_TO_BADGES_RECEIVED_ERROR
} from '../../actions/types';

const initialState = {
    hasReceivedBadges: false,
    badges: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
    case LISTEN_TO_BADGES_STARTED:
        return {
            ...state,
            hasReceivedBadges: null
        };

    case LISTEN_TO_BADGES_RECEIVED_DATA:
        return {
            ...state,
            hasReceivedBadges: true,
            badges: action.badges
        };

    case LISTEN_TO_BADGES_RECEIVED_ERROR:
        return {
            ...state,
            hasReceivedBadges: action.error
        };

    default:
        return state;
    }
};
