import React, { useCallback, useMemo, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Button, CircularProgress, Typography } from '@material-ui/core';

import { ContentHeader } from '../small_views/content_header/content_header';
import { AddUserDashboardDialog } from './add_dashboard_user_dialog/add_user_dashboard_dialog';
import { DashboardUsersTable } from './dashboard_users_table/dashboard_users_table';

import { useDashboardUsers } from '../../state/fetchers/dashboard_users/use_dashboard_users';

import { styles } from './manage_dashboard_users_styles';

const useStyles = makeStyles(styles);

const ManageDashboardUsersComponent = () => {
    const classes = useStyles();
    const { dashboardUsers, loading, error } = useDashboardUsers();
    const dashboardUsersLength = useMemo(() =>
        dashboardUsers && Object.keys(dashboardUsers).length, [dashboardUsers]);
    return (
        <>
            <Header {...{ dashboardUsersLength }} />
            <div className={classes.container}>
                <Content
                    {...{
                        dashboardUsers,
                        loading,
                        error
                    }}
                />
            </div>
        </>
    );
};

const Header = ({ dashboardUsersLength }) => {
    const [openAddUserDialog, setAddUserDialogOpenState] = useState(false);
    const setAddUserDialogOpened = useCallback(() => setAddUserDialogOpenState(true), []);
    const setAddUserDialogClosed = useCallback(() => setAddUserDialogOpenState(false), []);
    return (
        <>
            <AddUserDashboardDialog
                open={openAddUserDialog}
                onClose={setAddUserDialogClosed}
            />
            <ContentHeader
                title={`${dashboardUsersLength ? `${dashboardUsersLength} ` : ''}Utilisateurs - Dashboard`}
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={setAddUserDialogOpened}
                >
                    {'Ajouter un utilisateur'}
                </Button>
            </ContentHeader>
        </>
    );
};

const Content = ({
    dashboardUsers,
    loading,
    error
}) => {
    if (loading) {
        return (
            <CircularProgress />
        );
    }
    if (error) {
        return <Error {...{ error }} />;
    }
    if (dashboardUsers) {
        return <DashboardUsersTable {...{ dashboardUsers }} />;
    }
    return null;
};

const Error = ({ error }) => (
    <Typography>
        {(error && error.message) || error}
    </Typography>
);

export const ManageDashboardUsers = ManageDashboardUsersComponent;
