import React, { useCallback, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Button, IconButton, Typography, FormHelperText } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { UrlUpload } from '../../../../../../../../small_views/url_upload/url_upload';

import { styles } from './overlay_images_url_upload_fields_styles';

const useStyles = makeStyles(styles);

const OverlayImagesUrlUploadFieldsComponent = ({
    label,
    values,
    arrayHelpers,
    documentId,
    projectDocumentId,
    step,
    error,
    errors
}) => {
    const classes = useStyles();
    const { push, replace, remove } = arrayHelpers;

    const hasValues = useMemo(() => Boolean(values && values.length), [values]);

    return (
        <>
            <Typography>
                {label}
            </Typography>
            {hasValues && (
                <div className={classes.overlayImagesFieldsContainer}>
                    {values.map((value, index) => (
                        <div
                            key={`overlay_image_fields_${index}`}
                            className={classes.overlayImageField}
                        >
                            <UrlUpload
                                path={`/projects/${projectDocumentId}/steps/${documentId}/overlay-images`}
                                fileName={`overlay_image_${index}`}
                                id={`${step}_overlay_image_${index}`}
                                customClasses={{ container: classes.urlUpload }}
                                onChange={(url) => replace(index, url)}
                                variant="outlined"
                                fileHandlingProps={{
                                    accept: 'image/png'
                                }}
                                {...{ value }}
                            />
                            <IconButton
                                classes={{
                                    root: classes.iconButton
                                }}
                                onClick={() => remove(index)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    ))}
                </div>
            )}
            {error && (
                <FormHelperText error>
                    {error}
                </FormHelperText>
            )}
            <Button
                color="primary"
                variant="outlined"
                size="large"
                onClick={() => push('')}
                {...(!hasValues || error) && {
                    classes: {
                        root: classes.withMarginButton
                    }
                }}
            >
                {'Ajouter une image'}
            </Button>
        </>
    );
};

export const OverlayImagesUrlUploadFields = OverlayImagesUrlUploadFieldsComponent;
