import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { SetAsDraftAction } from './set_as_draft_action/set_as_draft_action';
import { SetAsPublishedAction } from './set_as_published_action/set_as_published_action';

import { styles } from './change_status_action_styles';

const useStyles = makeStyles(styles);

const ChangeStatusActionComponent = ({ documentId, status, draftProject }) => {
    const classes = useStyles();
    if (status === 'published') {
        return <SetAsDraftAction {...{ documentId }} />;
    }
    if (status === 'draft') {
        return <SetAsPublishedAction {...{ documentId }} />;
    }
    return null;
};

export const ChangeStatusAction = ChangeStatusActionComponent;
