import React from 'react';

import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { styles } from './phone_background_styles';

const useStyles = makeStyles(styles);

const PhoneBackgroundComponent = ({ customClasses = {}, ...other }) => {
    const classes = useStyles();
    return (
        <path
            className={cn(classes.container, customClasses.container)}
            d="m14.758273 5.8740621c-4.70114 0-8.4857901 3.7846491-8.4857901 8.4857869v162.241251c0 4.70114 3.7846501 8.4858 8.4857901 8.4858h65.733457c4.701138 0 8.485788-3.78466 8.485788-8.4858v-162.241251c0-4.7011378-3.78465-8.4857869-8.485788-8.4857869h-8.300784a1.4175986 1.4175986 0 0 0-1.417487 1.4174841v0.6526739c0 2.5541609-2.055892 4.6105709-4.610052 4.6105709h-37.076805c-2.554169 0-4.610059-2.05641-4.610059-4.6105709v-0.6526739a1.4175986 1.4175986 0 0 0-1.41748-1.4174841z"
            color="#000000"
            fill="url(#linearGradient2973)"
            {...other}
        />
    );
};

export const PhoneBackground = PhoneBackgroundComponent;
