import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField, useMediaQuery
} from '@material-ui/core';

import { FileHandling } from '../../small_views/file_handling/file_handling';
import { GenericConfirmDialog } from '../../small_views/generic_confirm_dialog/generic_confirm_dialog';

import { setBadge } from '../../../actions/miscellaneous/badges_actions';
import { uploadFileToStorage } from '../../../actions/miscellaneous/miscellanous_actions';

import { useCallbackOpen } from '../../../hooks/use_callback_open';

import { styles } from './edit_badge_dialog_styles';

const useStyles = makeStyles(styles);

const EditBadgeDialogComponent = ({ badgeId, badge = {}, open, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery('(max-width: 460px)');
    const [
        openNoIdBadgeImageUploadDialog,
        setNoIdBadgeImageDialogOpened,
        setNoIdBadgeImageDialogClosed
    ] = useCallbackOpen();

    const fileInputRef = useRef();

    const {
        imageUrl: defaultImageUrl = '',
        name: defaultName = '',
        text: defaultText = ''
    } = badge;

    const [id, setId] = useState(badgeId || '');
    const [imageUrl, setImageUrl] = useState(defaultImageUrl);
    const [name, setName] = useState(defaultName);
    const [text, setText] = useState(defaultText);

    const handleIdChange = useCallback((event) => {
        const { target: { value } } = event;
        setId(value);
    }, []);

    const handleImageUrlChange = useCallback((event) => {
        const { target: { value } } = event;
        setImageUrl(value);
    }, []);

    const handleNameChange = useCallback((event) => {
        const { target: { value } } = event;
        setName(value);
    }, []);

    const handleTextChange = useCallback((event) => {
        const { target: { value } } = event;
        setText(value);
    }, []);

    const disabled = useMemo(() => !id || !imageUrl || !name || !text, [id, imageUrl, name, text]);

    const handleSave = useCallback(() => {
        if (disabled) {
            return;
        }
        setBadge(id, {
            id,
            imageUrl,
            name,
            text
        })(dispatch)
            .then(() => {
                setId('');
                setImageUrl('');
                setName('');
                setText('');
                if (typeof onClose === 'function') {
                    onClose();
                }
            });
    }, [dispatch, badge, disabled, id, imageUrl, name, text, onClose]);

    const uploadBadgeImageCallback = useCallback((file) => uploadFileToStorage(file, null, '/badges')(dispatch)
        .then(setImageUrl), [dispatch]);

    const handleFileChange = useCallback((event) => {
        const { target: { files } } = event;
        const [firstFile] = files;
        if (!firstFile) {
            return;
        }
        if (!id) {
            setNoIdBadgeImageDialogOpened();
            return;
        }
        const { name: fileName } = firstFile;
        const extentionRegex = /\.[^/.]+$/;
        const extention = fileName.match(extentionRegex);
        uploadBadgeImageCallback(firstFile, `${id}${extention}`);
    }, [id, onClose]);

    const handleNoIdImageDialogConfirmation = useCallback(() => {
        const fileInputElement = fileInputRef.current;
        const [firstFile] = fileInputElement.files;
        uploadBadgeImageCallback(firstFile);
        setNoIdBadgeImageDialogClosed();
    }, [fileInputRef.current, setNoIdBadgeImageDialogClosed]);

    return (
        <>
            <NoIdBadgeImageUploadDialog
                open={openNoIdBadgeImageUploadDialog}
                onClose={setNoIdBadgeImageDialogClosed}
                onConfirm={handleNoIdImageDialogConfirmation}
            />
            <Dialog
                classes={{
                    paper: classes.dialogPaper
                }}
                {...{
                    open,
                    onClose,
                    fullScreen
                }}
            >
                <DialogTitle>
                    <Title {...{ badgeId }} />
                </DialogTitle>
                <DialogContent>
                    <TextField
                        classes={{
                            root: classes.textField
                        }}
                        fullWidth
                        variant="outlined"
                        label="ID"
                        onChange={handleIdChange}
                        value={id}
                    />
                    <Grid
                        classes={{
                            container: classes.gridContainer
                        }}
                        container
                        spacing={3}
                    >
                        <Grid item md={12} lg={8}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="URL de l'image"
                                onChange={handleImageUrlChange}
                                value={imageUrl}
                            />
                        </Grid>
                        <Grid item md={12} lg={4}>
                            <FileHandling
                                ref={fileInputRef}
                                id="edit-badge-dialog-file-upload"
                                accept="image/png"
                                multiple={false}
                                onChange={handleFileChange}
                            >
                                <Button
                                    component="span"
                                    classes={{
                                        root: classes.uploadButton
                                    }}
                                    variant="outlined"
                                >
                                    {'Upload'}
                                </Button>
                            </FileHandling>
                        </Grid>
                    </Grid>
                    <TextField
                        classes={{
                            root: classes.textField
                        }}
                        fullWidth
                        variant="outlined"
                        label="Nom"
                        onChange={handleNameChange}
                        value={name}
                    />
                    <TextField
                        classes={{
                            root: classes.textField
                        }}
                        fullWidth
                        variant="outlined"
                        label="Texte"
                        onChange={handleTextChange}
                        value={text}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        onClick={onClose}
                    >
                        {'Fermer'}
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        onClick={handleSave}
                        {...{ disabled }}
                    >
                        {'Sauvegarder'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const NoIdBadgeImageUploadDialog = (props) => (
    <GenericConfirmDialog
        content="Vous n'avez pas indiqué d'ID pour ce badge, le fichier placé dans la base de données aura le nom d'origine."
        {...props}
    />
);

const Title = ({ badgeId }) => {
    if (!badgeId) {
        return 'Créer un nouveau badge ?';
    }
    return 'Editer ce badge ?';
};

export const EditBadgeDialog = EditBadgeDialogComponent;
