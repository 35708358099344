import React from 'react';

import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { Divider } from '@material-ui/core';

import { styles } from './game_step_phone_question_styles';

const useStyles = makeStyles(styles);

const GameStepPhoneQuestionComponent = ({ children, customClasses = {} }) => {
    const classes = useStyles();
    return (
        <>
            <div
                className={cn(classes.container, customClasses.container)}
            >
                {children}
            </div>
            <Divider classes={{ root: classes.divider }} />
        </>
    );
};

export const GameStepPhoneQuestion = GameStepPhoneQuestionComponent;
