import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    IconButton
} from '@material-ui/core';

import {
    Delete as DeleteIcon,
    Edit as EditIcon
} from '@material-ui/icons';

import { GenericConfirmDialog } from '../../small_views/generic_confirm_dialog/generic_confirm_dialog';
import { EditBadgeDialog } from '../edit_badge_dialog/edit_badge_dialog';

import { deleteBadge } from '../../../actions/miscellaneous/badges_actions';

import { styles } from './badges_table_styles';

const useStyles = makeStyles(styles);

const BagesTableComponent = ({ badges }) => {
    const classes = useStyles();
    return (
        <Paper
            classes={{
                root: classes.paper
            }}
        >
            <Table className={classes.table} aria-label="projects list">
                <TableHead>
                    <TableRow>
                        <TableCell>{'Image'}</TableCell>
                        <TableCell>{'ID'}</TableCell>
                        <TableCell>{'Nom'}</TableCell>
                        <TableCell>{'Text'}</TableCell>
                        <TableCell align="right">{'Actions'}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(badges).map(([badgeId, badge]) => (
                        <BadgeRow key={`badge_row_${badgeId}`} {...{ badgeId, badge, classes }} />
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

const BadgeRow = ({ badgeId, badge, classes }) => {
    const { id, imageUrl, name, text } = badge;
    return (
        <TableRow>
            <TableCell component="th" scope="row">
                <img className={classes.badgeImage} src={imageUrl} alt={`Aperçu Badge ${name}`} />
            </TableCell>
            <TableCell>{id}</TableCell>
            <TableCell>{name}</TableCell>
            <TableCell classes={{ root: classes.textCell }}>
                {text}
            </TableCell>
            <TableCell align="right">
                <div className={classes.actions}>
                    <EditBadgeAction
                        {...{
                            badgeId,
                            badge
                        }}
                    />
                    <DeleteBadgeAction
                        {...{
                            badgeId
                        }}
                    />
                </div>
            </TableCell>
        </TableRow>
    );
};

const EditBadgeAction = ({ badgeId, badge }) => {
    const [openEditDialog, setEditDialogOpenState] = useState(false);
    const setEditDialogOpened = useCallback(() => setEditDialogOpenState(true));
    const setEditDialogClosed = useCallback(() => setEditDialogOpenState(false));
    return (
        <>
            <EditBadgeDialog
                open={openEditDialog}
                onClose={setEditDialogClosed}
                {...{
                    badgeId,
                    badge
                }}
            />
            <Tooltip title="Editer ce badge">
                <IconButton onClick={setEditDialogOpened}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
        </>
    );
};

const DeleteBadgeAction = ({ badgeId }) => {
    const dispatch = useDispatch();
    const [openConfirmDialog, setConfirmDialogOpenState] = useState(false);
    const setConfirmDialogOpened = useCallback(() => setConfirmDialogOpenState(true));
    const setConfirmDialogClosed = useCallback(() => setConfirmDialogOpenState(false));

    const handleConfirm = useCallback(() => {
        deleteBadge(badgeId)(dispatch)
            .then(setConfirmDialogClosed);
    }, [badgeId]);

    return (
        <>
            <GenericConfirmDialog
                open={openConfirmDialog}
                onConfirm={handleConfirm}
                onClose={setConfirmDialogClosed}
            />
            <Tooltip title="Supprimer ce badge">
                <IconButton onClick={setConfirmDialogOpened}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </>
    );
};

export const BadgesTable = BagesTableComponent;
