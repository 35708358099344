import React, { useCallback, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { Collapse, Drawer, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import {
    HomeRounded as HomeIcon,
    FormatPaintRounded as FormatPainIcon,
    PermMediaRounded as PermMediaIcon,
    PlayCircleFilled as PlayCircleIcon,
    Help as HelpIcon,
    List as ListIcon,
    Add as AddIcon,
    Face as FaceIcon,
    EmojiEvents as EmojiEventIcon,
    TagFaces as TagFacesIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';

import { CreateDraftProjectDialog } from '../small_views/create_draft_project_dialog/create_draft_project_dialog';

import { useDashboardUserRole } from '../../state/selectors/user_selectors';

import { styles } from './side_panel_styles';

const useStyles = makeStyles(styles);

const SidePanelComponent = ({ isSidePanelTemporary, open, onClose }) => {
    const classes = useStyles();
    return (
        <Drawer
            classes={{
                root: classes.drawer,
                paper: cn(classes.drawer, classes.paper)
            }}
            anchor="left"
            variant={isSidePanelTemporary ? 'temporary' : 'persistent'}
            open={!isSidePanelTemporary || open}
            {...{ onClose }}
        >
            <FirstList {...{ classes }} />
            <SecondList {...{ classes }} />
        </Drawer>
    );
};

const FirstList = ({ classes }) => {
    const role = useDashboardUserRole();
    const isAdmin = useMemo(() => role === 'admin', [role]);
    const [openProjectsSection, setProjectsSectionOpenState] = useState(false);
    const toggleProjectsSections = useCallback(() => setProjectsSectionOpenState(!openProjectsSection), [
        openProjectsSection
    ]);
    return (
        <List disablePadding>
            <ListItem className={cn(classes.title, classes.item, classes.itemCategory)}>{'GoGame'}</ListItem>
            <NavLink exact activeClassName={classes.activeLink} to="/">
                <ListItem className={cn(classes.item)} button>
                    <ListItemIcon className={classes.itemIcon}>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText
                        classes={{
                            primary: classes.itemText
                        }}
                        primary="Accueil"
                    />
                </ListItem>
            </NavLink>
            <ListItem onClick={toggleProjectsSections} className={cn(classes.item)} button>
                <ListItemIcon className={classes.itemIcon}>
                    <FormatPainIcon />
                </ListItemIcon>
                <ListItemText
                    classes={{
                        primary: classes.itemText
                    }}
                    primary="Projets"
                />
                {openProjectsSection ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={openProjectsSection} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <NavLink activeClassName={classes.activeLink} to="/projects">
                        <ListItem button className={cn(classes.item, classes.nestedItem)}>
                            <ListItemIcon className={classes.itemIcon}>
                                <ListIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.itemText
                                }}
                                primary="Liste des scénarios"
                            />
                        </ListItem>
                    </NavLink>
                    <CreateDraftProjectListItem {...{ classes }} />
                </List>
            </Collapse>
            <NavLink activeClassName={classes.activeLink} to="/badges">
                <ListItem button className={classes.item}>
                    <ListItemIcon className={classes.itemIcon}>
                        <EmojiEventIcon />
                    </ListItemIcon>
                    <ListItemText
                        classes={{
                            primary: classes.itemText
                        }}
                        primary="Liste des badges"
                    />
                </ListItem>
            </NavLink>
            <NavLink activeClassName={classes.activeLink} to="/medias">
                <ListItem className={cn(classes.item)} button>
                    <ListItemIcon className={classes.itemIcon}>
                        <PermMediaIcon />
                    </ListItemIcon>
                    <ListItemText
                        classes={{
                            primary: classes.itemText
                        }}
                        primary="Medias"
                    />
                </ListItem>
            </NavLink>
            {isAdmin && (
                <>
                    <NavLink activeClassName={classes.activeLink} to="/manage-dashboard-users">
                        <ListItem className={cn(classes.item)} button>
                            <ListItemIcon className={classes.itemIcon}>
                                <FaceIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.itemText
                                }}
                                primary="Utilisateurs - Dashboard"
                            />
                        </ListItem>
                    </NavLink>
                    <NavLink activeClassName={classes.activeLink} to="/manage-game-users">
                        <ListItem className={cn(classes.item)} button>
                            <ListItemIcon className={classes.itemIcon}>
                                <TagFacesIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.itemText
                                }}
                                primary="Utilisateurs - Jeu"
                            />
                        </ListItem>
                    </NavLink>
                </>
            )}
            <Divider className={classes.divider} />
        </List>
    );
};

const SecondList = ({ classes }) => (
    <List disablePadding>
        <Divider className={classes.divider} />
        <NavLink activeClassName={classes.activeLink} to="/tutorials">
            <ListItem className={cn(classes.item)} button>
                <ListItemIcon className={classes.itemIcon}>
                    <PlayCircleIcon />
                </ListItemIcon>
                <ListItemText
                    classes={{
                        primary: classes.itemText
                    }}
                    primary="Tutoriels"
                />
            </ListItem>
        </NavLink>
        <NavLink activeClassName={classes.activeLink} to="/support">
            <ListItem className={cn(classes.item)} button>
                <ListItemIcon className={classes.itemIcon}>
                    <HelpIcon />
                </ListItemIcon>
                <ListItemText
                    classes={{
                        primary: classes.itemText
                    }}
                    primary="Support"
                />
            </ListItem>
        </NavLink>
    </List>
);

const CreateDraftProjectListItem = ({ classes }) => {
    const [openDialog, setDialogOpenState] = useState(false);
    const setDialogOpened = useCallback(() => setDialogOpenState(true), []);
    const setDialogClosed = useCallback(() => setDialogOpenState(false), []);
    return (
        <>
            <CreateDraftProjectDialog
                open={openDialog}
                onClose={setDialogClosed}
            />
            <ListItem
                button
                className={cn(classes.item, classes.nestedItem)}
                onClick={setDialogOpened}
            >
                <ListItemIcon className={classes.itemIcon}>
                    <AddIcon />
                </ListItemIcon>
                <ListItemText
                    classes={{
                        primary: classes.itemText
                    }}
                    primary="Créer un scénario"
                />
            </ListItem>
        </>
    );
};

export const SidePanel = SidePanelComponent;
