import React, { useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText
} from '@material-ui/core';

import { styles } from './badges_list_styles';

const useStyles = makeStyles(styles);

export const BadgesListComponent = ({ onSelect, badges }) => {
    const classes = useStyles();
    if (!badges) {
        return null;
    }
    return (
        <List>
            {Object.entries(badges).map(([documentId, badge]) => (
                <BadgesListItem
                    key={`badges_list_item_${documentId}`}
                    {...{ documentId, badge, onSelect }}
                />
            ))}
        </List>
    );
};

const BadgesListItem = ({ documentId, onSelect, badge }) => {
    const { imageUrl, name } = badge;
    const handleSelect = useCallback(() => {
        if (typeof onSelect === 'function') {
            onSelect(documentId);
        }
    }, [onSelect, documentId]);
    return (
        <ListItem
            button
            onClick={handleSelect}
        >
            <ListItemAvatar>
                <Avatar alt={`Badge ${name}`} src={imageUrl} />
            </ListItemAvatar>
            <ListItemText
                primary={name}
            />
        </ListItem>
    );
};

export const BadgesList = BadgesListComponent;
