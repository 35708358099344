import { flex } from '../../utils/styles_utils';

const { center } = flex;

export const styles = (theme) => ({
    container: {
        height: '100%',
        minHeight: `calc(100vh - 60px - ${theme.spacing(6) * 2}px)`,
        width: '100%',
        ...center
    },
    iframeContainer: {
        maxWidth: '100%',
        borderRadius: 4,
        overflow: 'hidden',
        ...center
    }
});
