import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import { Card, CardContent, Stepper, Step, StepLabel } from '@material-ui/core';

import { ErrorTypography } from '../../../small_views/error_typography/error_typography';

import { STEPS_DATA } from './edit_project_steps/edit_project_steps_data';

import { styles } from './edit_project_card_styles';

const useStyles = makeStyles(styles);

const EditProjectCardComponent = ({ documentId, status, project, draftProject }) => {
    const classes = useStyles();
    const { step: stepParam } = useParams();

    const stepData = useMemo(() => Object.values(STEPS_DATA).find(({ param }) => param === stepParam), [
        stepParam,
        STEPS_DATA
    ]);

    const activeStep = useMemo(() => Object.values(STEPS_DATA).findIndex(({ param }) => param === stepParam), [
        stepParam,
        STEPS_DATA
    ]);

    if (!stepData) {
        return <ErrorTypography error={`L'étape '${stepParam}' n'est pas valide.`} />;
    }

    const { component: StepComponent } = stepData;

    return (
        <div className={classes.container}>
            <Stepper classes={{ root: classes.stepper }} {...{ activeStep }} alternativeLabel>
                {Object.entries(STEPS_DATA).map(([stepId, { param, name }]) => (
                    <Step key={`step_${stepId}`}>
                        <StepLabel>
                            <Link to={`/edit-project/${status}/${documentId}/${param}`}>
                                {name}
                            </Link>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Card
                classes={{
                    root: classes.card
                }}
            >
                <CardContent classes={{ root: classes.content }}>
                    {StepComponent && (
                        <StepComponent
                            {...{
                                stepParam,
                                documentId,
                                status,
                                draftProject,
                                project
                            }}
                        />
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export const EditProjectCard = EditProjectCardComponent;
