import React, { useCallback, useMemo, useState } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import { useMediaQuery } from '@material-ui/core';

import { SidePanel } from '../../side_panel/side_panel';
import { ContentTopActions } from '../../small_views/content_top_actions/content_top_actions';

import { Home } from '../../home/home';
import { ProjectsList } from '../../projects/projects_list/projects_list';
import { EditProject } from '../../projects/edit_project/edit_project';
import { Badges } from '../../badges/badges';
import { Tutorials } from '../../tutorials/tutorials';
import { Support } from '../../support/support';
import { ManageDashboardUsers } from '../../manage_dashboard_users/manage_dashboard_users';
import { ManageGameUsers } from '../../manage_game_users/manage_game_users';
import { EditingDatabaseLinearProgress } from '../../small_views/editing_database_linear_progress/editing_database_linear_progress';
import { ManageMedias } from '../../manage_medias/manage_medias';

import { useDashboardUserRole } from '../../../state/selectors/user_selectors';
import { useCallbackOpen } from '../../../hooks/use_callback_open';

import { styles } from './logged_routes_styles';

const useStyles = makeStyles(styles);

const LoggedRoutesComponent = () => {
    const classes = useStyles();
    const role = useDashboardUserRole();
    const isAdmin = useMemo(() => role === 'admin', [role]);
    const isSidePanelTemporary = useMediaQuery('(max-width: 1150px)', {
        defaultMatches: true
    });
    const [openSidePanel, setSidePanelOpened, setSidePanelClosed] = useCallbackOpen();

    return (
        <>
            <SidePanel {...{ isSidePanelTemporary }} open={openSidePanel} onClose={setSidePanelClosed} />
            <div className={classes.contentContainer}>
                <ContentTopActions {...{ isSidePanelTemporary, setSidePanelOpened }}>
                    <EditingDatabaseLinearProgress />
                </ContentTopActions>
                <div className={classes.content}>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/projects" component={ProjectsList} />
                        <Route path="/edit-project/:status/:documentId/:step" component={EditProject} />
                        <Route path="/badges" component={Badges} />
                        <Route path="/medias" component={ManageMedias} />
                        <Route path="/tutorials" component={Tutorials} />
                        <Route path="/support" component={Support} />
                        {isAdmin && (
                            <>
                                <Route path="/manage-dashboard-users" component={ManageDashboardUsers} />
                                <Route path="/manage-game-users" component={ManageGameUsers} />
                            </>
                        )}
                        <Redirect from="*" to="/" />
                    </Switch>
                </div>
            </div>
        </>
    );
};

export const LoggedRoutes = LoggedRoutesComponent;
