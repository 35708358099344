export const styles = ({
    paper: {
        width: '100%',
        overflowX: 'auto'
    },
    nameCell: {
        minWidth: 150
    },
    actionsCell: {
        minWidth: 130
    }
});
