import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField
} from '@material-ui/core';

import { createDraftProject } from '../../../actions/projects_actions';

import { STEPS_DATA } from '../../projects/edit_project/edit_project_card/edit_project_steps/edit_project_steps_data';

import { useUid } from '../../../state/selectors/user_selectors';

import { styles } from './create_draft_projet_dialog_styles';

const useStyles = makeStyles(styles);

const CreateDraftProjectDialogComponent = ({ open, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const uid = useUid();
    const [id, setId] = useState('');

    const handleIdChange = useCallback((event) => {
        const {
            target: { value }
        } = event;
        setId(value);
    }, []);

    const handleCreation = useCallback(() => {
        createDraftProject(id, uid)(dispatch).then((draftId) => {
            if (typeof onClose === 'function') {
                onClose();
            }
            history.push(`/edit-project/draft/${draftId}/${Object.values(STEPS_DATA)[0].param}`);
        });
    }, [id, uid, dispatch, history]);

    return (
        <Dialog {...{ open, onClose }}>
            <DialogTitle>{'Créer un nouveau scnénario'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {"Le scénario sera placé dans les brouillons jusqu'à sa publication."}
                    <br />
                    {"Pour que l'ID soit généré automatiquement, merci de laisser le champ vide."}
                </DialogContentText>
                <TextField
                    classes={{
                        root: classes.textField
                    }}
                    fullWidth
                    variant="outlined"
                    value={id}
                    onChange={handleIdChange}
                    label="ID"
                />
            </DialogContent>
            <DialogActions>
                <Button size="small" onClick={onClose}>
                    {'Fermer'}
                </Button>
                <Button color="primary" size="small" onClick={handleCreation}>
                    {'Créer'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const CreateDraftProjectDialog = CreateDraftProjectDialogComponent;
