export const styles = (theme) => ({
    form: {
        width: '100%'
    },
    columnsContainer: {
        display: 'flex'
    },
    column: {
        margin: theme.spacing(0, 1.5),
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    stepField: {
        margin: theme.spacing(1.5, 0)
    },
    actions: {
        justifyContent: 'flex-end'
    },
    '@media screen and (max-width: 860px)': {
        columnsContainer: {
            flexDirection: 'column'
        }
    }
});
