import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const config = {
    apiKey: 'AIzaSyBsZr06RrA7cJqbISZyQ-KepV6He7wOvf4',
    authDomain: 'testing-firestore-200bc.firebaseapp.com',
    databaseURL: 'https://testing-firestore-200bc.firebaseio.com',
    projectId: 'testing-firestore-200bc',
    storageBucket: 'testing-firestore-200bc.appspot.com',
    messagingSenderId: '345031791973',
    appId: '1:345031791973:web:52abcbadad3d6a85123d8c'
};

firebase.initializeApp(config);

export default firebase;
