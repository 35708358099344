import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listenToHomeCard } from '../../../actions/home_actions';

export const useHomeCard = (cardId) => {
    const dispatch = useDispatch();
    const card = useSelector(
        ({ home: { cards: inReducerCards } }) => inReducerCards[cardId]
    );
    const hasReceivedCard = useSelector(
        ({ home: { hasReceivedCards: inReducerHasReceivedCards } }) =>
            inReducerHasReceivedCards[cardId]
    );

    useEffect(() => {
        if (hasReceivedCard === undefined) {
            console.log('[useHomeCard] Listening to home card...', { cardId });
            listenToHomeCard(cardId)(dispatch);
        }
    }, [hasReceivedCard, cardId, dispatch]);

    return {
        card,
        loading: hasReceivedCard === null,
        error: hasReceivedCard !== true && hasReceivedCard
    };
};
