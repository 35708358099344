import {
    DATABASE_EDIT_SUBMIT,
    DATABASE_EDIT_FINISH,
    LISTEN_TO_HOME_CARD_STARTED,
    LISTEN_TO_HOME_CARD_RECEIVED_DATA,
    LISTEN_TO_HOME_CARD_RECEIVED_ERROR
} from './types';

import firebase from '../firebase';

const database = firebase.firestore();

export const setHomeCardObjective = (cardId, objective) => (dispatch) => {
    dispatch({
        type: DATABASE_EDIT_SUBMIT
    });

    const updates = {
        objective
    };
    const documentReference = database.collection('dashboard_home').doc(cardId);

    return documentReference
        .get()
        .then((document) => {
            if (document.exists) {
                return documentReference.update(updates);
            }
            return documentReference.set(updates);
        })
        .finally(() =>
            dispatch({
                type: DATABASE_EDIT_FINISH
            }));
};

export const setHomeCardList = (cardId, value) => (dispatch) => {
    dispatch({
        type: DATABASE_EDIT_SUBMIT
    });

    const updates = { value };
    const documentReference = database.collection('dashboard_home').doc(cardId);

    return documentReference
        .get()
        .then((document) => {
            if (document.exists) {
                return documentReference.update(updates);
            }
            return documentReference.set(updates);
        })
        .finally(() =>
            dispatch({
                type: DATABASE_EDIT_FINISH
            }));
};

export const listenToHomeCard = (cardId) => (dispatch) => {
    dispatch({
        type: LISTEN_TO_HOME_CARD_STARTED,
        cardId
    });
    return database
        .collection('dashboard_home')
        .doc(cardId)
        .onSnapshot(
            (snapshot) => {
                dispatch({
                    type: LISTEN_TO_HOME_CARD_RECEIVED_DATA,
                    cardId,
                    card: snapshot.data()
                });
            },
            (error) => {
                dispatch({
                    type: LISTEN_TO_HOME_CARD_RECEIVED_ERROR,
                    cardId,
                    error
                });
            }
        );
};
