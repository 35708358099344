import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Tooltip, IconButton } from '@material-ui/core';

import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';

import { StatusChip } from '../../status_chip/status_chip';
import { GenericConfirmDialog } from '../../../small_views/generic_confirm_dialog/generic_confirm_dialog';

import { useCallbackOpen } from '../../../../hooks/use_callback_open';

import { deleteDraftProject } from '../../../../actions/projects_actions';

import { STEPS_DATA } from '../../edit_project/edit_project_card/edit_project_steps/edit_project_steps_data';

import { styles } from './projects_list_table_styles';

const useStyles = makeStyles(styles);

const addStatusToProjects = (status, projects) =>
    Object.entries(projects || {}).reduce((acc, [id, value]) => {
        const accCopy = acc;
        accCopy[id] = {
            ...value,
            status
        };
        return accCopy;
    }, {});

const ProjectsListTableComponent = ({ projects, draftProjects }) => {
    const classes = useStyles();

    const mergedProjects = useMemo(
        () => ({
            ...addStatusToProjects('published', projects),
            ...addStatusToProjects('draft', draftProjects)
        }),
        [projects, draftProjects]
    );

    return (
        <Paper
            classes={{
                root: classes.paper
            }}
        >
            <Table className={classes.table} aria-label="projects list">
                <TableHead>
                    <TableRow>
                        <TableCell>{'ID'}</TableCell>
                        <TableCell>{'Status'}</TableCell>
                        <TableCell>{'Nom - FR'}</TableCell>
                        <TableCell>{'Nom - EN'}</TableCell>
                        <TableCell>{'Points'}</TableCell>
                        <TableCell>{'Temps'}</TableCell>
                        <TableCell align="right">{'Actions'}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(mergedProjects).map(([documentId, project]) => (
                        <ProjectRow key={`project_row_${documentId}`} {...{ documentId, project, classes }} />
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

const ProjectRow = ({ documentId, project, classes }) => {
    const { id, status, names, points, time } = project;
    const firstStepDataParam = useMemo(() => Object.values(STEPS_DATA)[0].param, [STEPS_DATA])

    return (
        <TableRow>
            <TableCell component="th" scope="project">
                {id || documentId}
            </TableCell>
            <TableCell>
                <StatusChip {...{ status }} />
            </TableCell>
            <TableCell classes={{ root: classes.nameCell }}>{names && names.fr}</TableCell>
            <TableCell classes={{ root: classes.nameCell }}>{names && names.en}</TableCell>
            <TableCell>{points}</TableCell>
            <TableCell>{time}</TableCell>
            <TableCell classes={{ root: classes.actionsCell }} align="right">
                <Link to={`/edit-project/${status}/${documentId}/${firstStepDataParam}`}>
                    <Tooltip title="Editer ce projet">
                        <IconButton>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </Link>
                <DeleteProjectAction {...{ documentId, status }} />
            </TableCell>
        </TableRow>
    );
};

const DeleteProjectAction = ({ documentId }) => {
    const dispatch = useDispatch();
    const [
        openConfirmDeletionDialog,
        setConfirmDeletionDialogOpened,
        setConfirmDeletionDialogClosed
    ] = useCallbackOpen();

    const handleConfirm = useCallback(() => {
        deleteDraftProject(documentId)(dispatch).then(() => setConfirmDeletionDialogClosed);
    }, [documentId, setConfirmDeletionDialogClosed, dispatch]);

    return (
        <>
            <GenericConfirmDialog
                open={openConfirmDeletionDialog}
                onClose={setConfirmDeletionDialogClosed}
                onConfirm={handleConfirm}
            />
            <Tooltip title="Supprimer ce projet">
                <IconButton onClick={setConfirmDeletionDialogOpened}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </>
    );
};

export const ProjectsListTable = ProjectsListTableComponent;
