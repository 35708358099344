export const styles = (theme) => ({
    fieldsContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    field: {
        margin: theme.spacing(2, 0),
        minWidth: 120
    },
    emailField: {
        minWidth: 200,
        marginRight: theme.spacing(3),
        flex: 1
    }
});
