import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { styles } from './support_styles';

const useStyles = makeStyles(styles);

const SupportComponent = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <iframe
                title="GoGame Dashboard Support"
                src="https://docs.google.com/forms/d/e/1FAIpQLSdFVFO55r4lPm7_F8Fi1-tFzV_ypabzTi17akoDfzWfy55OJw/viewform?embedded=true"
                width="640"
                height="1069"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
            />
        </div>
    );
};

export const Support = SupportComponent;
