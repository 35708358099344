import {
    LISTEN_TO_GAME_USERS_STARTED,
    LISTEN_TO_GAME_USERS_RECEIVED_DATA,
    LISTEN_TO_GAME_USERS_RECEIVED_ERROR
} from '../../actions/types';

const initialState = {
    hasReceivedGameUsers: false,
    gameUsers: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
    case LISTEN_TO_GAME_USERS_STARTED:
        return {
            ...state,
            hasReceivedGameUsers: null
        };

    case LISTEN_TO_GAME_USERS_RECEIVED_DATA:
        return {
            ...state,
            hasReceivedGameUsers: true,
            gameUsers: action.gameUsers
        };

    case LISTEN_TO_GAME_USERS_RECEIVED_ERROR:
        return {
            ...state,
            hasReceivedGameUsers: action.error
        };

    default:
        return state;
    }
};
