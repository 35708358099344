import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Card, CardContent, LinearProgress, Typography } from '@material-ui/core';

import { CardTitle } from '../../../small_views/card_title/card_title';
import { EditableButton } from '../../../small_views/editable_button/editable_button';
import { EditListDialog } from './edit_list_dialog/edit_list_dialog';

import { useDashboardUserRole } from '../../../../state/selectors/user_selectors';
import { useCallbackOpen } from '../../../../hooks/use_callback_open';

import { styles } from './list_length_card_styles';

const useStyles = makeStyles(styles);

const ListLengthCardComponent = ({ documentId, card, name, color }) => {
    const classes = useStyles();
    const [openEditListDialog, setEditListDialogOpened, setEditListDialogClosed] = useCallbackOpen();
    const { value = [] } = card || {};
    const role = useDashboardUserRole();
    const isAdmin = useMemo(() => role === 'admin', [role]);
    if (!card) {
        return null;
    }
    return (
        <>
            <EditListDialog open={openEditListDialog} onClose={setEditListDialogClosed} {...{ value, documentId }} />
            <Card>
                <CardContent>
                    <CardTitle>{name}</CardTitle>
                    <EditNode {...{ isAdmin, setEditListDialogOpened }} />
                    <div className={classes.progressContainer}>
                        <Typography
                            classes={{
                                root: classes.progressTypography
                            }}
                        >
                            {value.length}
                        </Typography>
                        <LinearProgress
                            classes={{
                                root: classes.progressRoot
                            }}
                            value={100}
                            variant="determinate"
                            {...{ color }}
                        />
                    </div>
                </CardContent>
            </Card>
        </>
    );
};

const EditNode = ({ isAdmin, setEditListDialogOpened }) => {
    if (!isAdmin) {
        return null;
    }
    return <EditableButton onClick={setEditListDialogOpened}>{'Editer la liste'}</EditableButton>;
};

export const ListLengthCard = ListLengthCardComponent;
