import * as Yup from 'yup';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import set from 'lodash/set';

const getFieldsValues = (stepFields = {}) => (Array.isArray(stepFields)
    ? stepFields.reduce((acc, fields) => {
        const accCopy = acc;
        Object.values(fields).forEach((value) => {
            accCopy.push(value);
        });
        return accCopy;
    }, [])
    : Object.values(stepFields));

export const generateValidationSchema = (stepFields) => Yup.object(
    getFieldsValues(stepFields)
        .filter((field) => field.path && field.validationRule)
        .reduce((shape, field) => {
            const shapeCopy = shape;
            shapeCopy[field.path.replace(/\./g, '_')] = field.validationRule();
            return shapeCopy;
        }, {})
);

const getInitialValue = (field) => {
    if (typeof field.defaultValue !== 'undefined') {
        return field.defaultValue;
    }
    if (field.type === 'array') {
        return [];
    }
    if (field.type === 'boolean') {
        return false;
    }
    if (field.type === 'object') {
        return {};
    }
    if (field.type === 'date') {
        return new Date().getTime();
    }
    return '';
};
export const generateInitialValues = (stepFields, data) => {
    const dataCopy = cloneDeep(data);

    const values = getFieldsValues(stepFields);

    return values
        .filter((field) => field.path)
        .reduce((initialValues, field) => {
            const initialValuesCopy = initialValues;
            const currentValue = get(dataCopy, field.path);
            if (field.type === 'object') {
                initialValuesCopy[field.path.replace(/\./g, '_')] = merge(
                    {},
                    pick(getInitialValue(field), Object.keys(currentValue || {})),
                    currentValue
                );
            } else if (currentValue === undefined || currentValue === '') {
                initialValuesCopy[field.path.replace(/\./g, '_')] = getInitialValue(field);
            } else if (field.initialTransform) {
                initialValuesCopy[field.path.replace(/\./g, '_')] = field.initialTransform(currentValue);
            } else {
                initialValuesCopy[field.path.replace(/\./g, '_')] = currentValue;
            }
            return initialValuesCopy;
        }, {});
};

export const generateFinalValues = (stepFields, formValues, baseObject, mergeObjects = false, submitTransformArguments = {}) => {
    const finalValues = cloneDeep(baseObject);
    getFieldsValues(stepFields)
        .filter((field) => field.path)
        .forEach((field) => {
            let formValue = formValues[field.path.replace(/\./g, '_')];
            if (field.submitTransform) {
                formValue = field.submitTransform(formValue, submitTransformArguments);
            }
            if (field.type === 'object') {
                let previousValue = get(finalValues, field.path, {});
                if (!mergeObjects) {
                    previousValue = pick(previousValue, Object.keys(formValue || {}));
                }
                set(finalValues, field.path, merge({}, previousValue, formValue));
            } else {
                set(finalValues, field.path, formValue);
            }
        });
    return finalValues;
};

export const getFirstInvalidStepIndex = (currentStepIndex, stepsData, newJobData, formatMessage) => {
    const stepsValues = Object.values(stepsData);
    for (let i = 0; i < stepsValues.length; i += 1) {
        try {
            const stepFields = stepsValues[i].fields;
            const validationSchema = generateValidationSchema(stepFields, formatMessage);
            const flattenedValues = generateInitialValues(stepFields, newJobData);
            validationSchema.validateSync(flattenedValues);
        } catch {
            return i;
        }
    }
    return currentStepIndex;
};
