import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listenToProjects, listenToProject } from '../../../actions/projects_actions';

export const useProjects = (createdBy) => {
    const dispatch = useDispatch();
    const oldCreatedByReference = useRef(createdBy);
    const unsubscribeListenerReference = useRef();

    const projects = useSelector(({ projects: { projects: inReducerProjects } }) => inReducerProjects);
    const hasReceivedAllProjects = useSelector(
        ({ projects: { hasReceivedAllProjects: inReducerHasReceivedAllProjects } }) => inReducerHasReceivedAllProjects
    );

    useEffect(() => {
        const isCreatedByDifferent = createdBy !== oldCreatedByReference.current;
        if (hasReceivedAllProjects === false || isCreatedByDifferent) {
            console.log('[useProjects] Listening to projects...', { createdBy });
            if (isCreatedByDifferent && unsubscribeListenerReference.current) {
                unsubscribeListenerReference.current();
            }
            unsubscribeListenerReference.current = listenToProjects(createdBy)(dispatch);
        }
    }, [
        hasReceivedAllProjects,
        dispatch,
        createdBy,
        oldCreatedByReference.current,
        unsubscribeListenerReference.current
    ]);

    useEffect(() => {
        oldCreatedByReference.current = createdBy;
    }, [createdBy]);

    return {
        projects,
        loading: hasReceivedAllProjects === null,
        error: hasReceivedAllProjects !== true && hasReceivedAllProjects
    };
};

export const useProject = (documentId) => {
    const dispatch = useDispatch();
    const project = useSelector(({ projects: { projects: inReducerProjects } }) => inReducerProjects[documentId]);
    const hasReceivedProject = useSelector(
        ({ projects: { hasReceivedProjects: inReducerHasReceivedProjects } }) =>
            inReducerHasReceivedProjects[documentId]
    );

    useEffect(() => {
        if (hasReceivedProject === undefined) {
            console.log('[useProject] Listening to project...', { documentId });
            listenToProject(documentId)(dispatch);
        }
    }, [hasReceivedProject, documentId, dispatch]);

    return {
        project,
        loading: hasReceivedProject === null,
        error: hasReceivedProject !== true && hasReceivedProject
    };
};
