import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import { Formik, Form, Field } from 'formik';
import { pick } from 'lodash';

import { Button, CardActions } from '@material-ui/core';

import {
    generateInitialValues,
    generateValidationSchema,
    generateFinalValues
} from '../../../../../utils/formik_tools';

import { GENERAL_FIELDS } from './general_form_fields';

import { updateOrCreateProject } from '../../../../../actions/projects_actions';

import { styles } from './general_form_styles';

const useStyles = makeStyles(styles);

const GeneralFormComponent = ({ stepParam, documentId, status, project, draftProject }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const initialValues = useMemo(() => generateInitialValues(GENERAL_FIELDS, project || draftProject), [
        GENERAL_FIELDS
    ]);

    const validationSchema = useMemo(() => generateValidationSchema(GENERAL_FIELDS), [GENERAL_FIELDS]);

    const handleSubmit = useCallback(
        (values, actions) => {
            const baseObject = project || draftProject;
            const finalValues = generateFinalValues(GENERAL_FIELDS, values, baseObject, false, { documentId });
            return updateOrCreateProject(
                documentId,
                finalValues,
                status
            )(dispatch).then(() => {
                actions.setSubmitting(false);
                history.push(`/edit-project/${status}/${documentId}/game-steps`);
            });
        },
        [GENERAL_FIELDS, documentId, status, dispatch, project, draftProject]
    );

    return (
        <Formik
            {...{
                initialValues,
                validationSchema
            }}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmit}
        >
            {(bag) => {
                const { values, errors } = bag;
                return (
                    <Form className={classes.form}>
                        {GENERAL_FIELDS && (
                            <div className={classes.columnsContainer}>
                                {GENERAL_FIELDS.map((fieldsObject, index) => (
                                    <div key={`fields_column_${index}`} className={classes.column}>
                                        {Object.values(fieldsObject).map((stepField) => (
                                            <div
                                                key={`step_${stepParam}_${stepField.path}`}
                                                className={classes.stepField}
                                            >
                                                <StepField
                                                    step={stepParam}
                                                    {...{
                                                        stepField,
                                                        errors,
                                                        values
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )}
                        <CardActions classes={{
                            root: classes.actions
                        }}
                        >
                            <Button type="submit" color="secondary">
                                {'Sauvegarder'}
                            </Button>
                        </CardActions>
                    </Form>
                );
            }}
        </Formik>
    );
};

const StepField = memo(({ step, stepField, errors }) => {
    const sanitizedName = stepField.path && stepField.path.replace(/\./g, '_');
    const propsToPass = {
        step,
        name: sanitizedName,
        error: errors[sanitizedName],
        cssSelector: stepField.path && stepField.path.replace(/\./g, '-'),
        label: stepField.label,
        type: stepField.type,
        ...pick(stepField, ['placeholder', 'getData', 'defaultValue'])
    };
    return <Field component={stepField.component} {...propsToPass} />;
});

export const GeneralForm = GeneralFormComponent;
