export const styles = (theme) => ({
    container: {
        padding: theme.spacing(4, 8),
        display: 'flex',
        alignItems: 'center'
    },
    content: {
        width: '100%',
        maxWidth: 800,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    title: {
        fontWeight: 700
    },
    subTitle: {
        margin: theme.spacing(3, 0)
    },
    button: {
        padding: theme.spacing(1.5, 3),
        width: 'fit-content'
    },
    '@media screen and (max-width: 550px)': {
        container: {
            padding: theme.spacing(2, 4)
        }
    }
});
