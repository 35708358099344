import firebase from '../firebase';

import {
    DATABASE_EDIT_SUBMIT,
    DATABASE_EDIT_FINISH,
    LISTEN_TO_PROJECT_STARTED,
    LISTEN_TO_PROJECT_RECEIVED_DATA,
    LISTEN_TO_PROJECT_RECEIVED_ERROR,
    LISTEN_TO_PROJECTS_STARTED,
    LISTEN_TO_PROJECTS_RECEIVED_DATA,
    LISTEN_TO_PROJECTS_RECEIVED_ERROR,
    LISTEN_TO_DRAFT_PROJECTS_STARTED,
    LISTEN_TO_DRAFT_PROJECTS_RECEIVED_DATA,
    LISTEN_TO_DRAFT_PROJECTS_RECEIVED_ERROR,
    LISTEN_TO_DRAFT_PROJECT_STARTED,
    LISTEN_TO_DRAFT_PROJECT_RECEIVED_DATA,
    LISTEN_TO_DRAFT_PROJECT_RECEIVED_ERROR,
    LISTEN_TO_GAME_STARTED,
    LISTEN_TO_GAME_RECEIVED_DATA,
    LISTEN_TO_GAME_RECEIVED_ERROR
} from './types';

import { getObjectFromCollectionSnapshot } from '../utils/firestore_utils';

const database = firebase.firestore();

export const listenToProjects = (createdBy) => (dispatch) => {
    dispatch({
        type: LISTEN_TO_PROJECTS_STARTED
    });
    let collectionReference = database.collection('tracks');
    if (createdBy) {
        collectionReference = collectionReference.where('createdBy', '==', createdBy);
    }
    return collectionReference.onSnapshot(
        (snapshot) => {
            dispatch({
                type: LISTEN_TO_PROJECTS_RECEIVED_DATA,
                projects: getObjectFromCollectionSnapshot(snapshot)
            });
        },
        (error) => {
            dispatch({
                type: LISTEN_TO_PROJECTS_RECEIVED_ERROR,
                error
            });
        }
    );
};

export const listenToDraftProjects = (createdBy) => (dispatch) => {
    dispatch({
        type: LISTEN_TO_DRAFT_PROJECTS_STARTED
    });
    let collectionReference = database.collection('dashboard_draft_tracks');
    if (createdBy) {
        collectionReference = collectionReference.where('createdBy', '==', createdBy);
    }
    return collectionReference.onSnapshot(
        (snapshot) => {
            dispatch({
                type: LISTEN_TO_DRAFT_PROJECTS_RECEIVED_DATA,
                draftProjects: getObjectFromCollectionSnapshot(snapshot)
            });
        },
        (error) => {
            dispatch({
                type: LISTEN_TO_DRAFT_PROJECTS_RECEIVED_ERROR,
                error
            });
        }
    );
};

export const listenToProject = (documentId) => (dispatch) => {
    dispatch({
        type: LISTEN_TO_PROJECT_STARTED,
        documentId
    });
    return database
        .collection('tracks')
        .doc(documentId)
        .onSnapshot(
            (snapshot) => {
                dispatch({
                    type: LISTEN_TO_PROJECT_RECEIVED_DATA,
                    documentId,
                    project: snapshot.data()
                });
            },
            (error) => {
                dispatch({
                    type: LISTEN_TO_PROJECT_RECEIVED_ERROR,
                    documentId,
                    error
                });
            }
        );
};

export const listenToDraftProject = (documentId) => (dispatch) => {
    dispatch({
        type: LISTEN_TO_DRAFT_PROJECT_STARTED,
        documentId
    });
    return database
        .collection('dashboard_draft_tracks')
        .doc(documentId)
        .onSnapshot(
            (snapshot) => {
                dispatch({
                    type: LISTEN_TO_DRAFT_PROJECT_RECEIVED_DATA,
                    documentId,
                    draftProject: snapshot.data()
                });
            },
            (error) => {
                dispatch({
                    type: LISTEN_TO_DRAFT_PROJECT_RECEIVED_ERROR,
                    documentId,
                    error
                });
            }
        );
};

export const createDraftProject = (id, uid) => (dispatch) => {
    dispatch({
        type: DATABASE_EDIT_SUBMIT
    });
    let finalId = null;
    if (id) {
        finalId = id;
    } else {
        finalId = database.collection('dashboard_draft_tracks').doc().id;
    }
    return database
        .collection('dashboard_draft_tracks')
        .doc(finalId)
        .set({ id: finalId, createdBy: uid })
        .finally(() => {
            dispatch({
                type: DATABASE_EDIT_FINISH
            });
        })
        .then(() => finalId);
};

export const deleteDraftProject = (documentId) => (dispatch) => {
    dispatch({
        type: DATABASE_EDIT_SUBMIT
    });
    return database
        .collection('dashboard_draft_tracks')
        .doc(documentId)
        .delete()
        .finally(() =>
            dispatch({
                type: DATABASE_EDIT_FINISH
            }));
};

export const setPublishedProjectAsDraft = (documentId) => async (dispatch) => {
    dispatch({
        type: DATABASE_EDIT_SUBMIT
    });
    const documentReference = database.collection('tracks').doc(documentId);
    const document = await documentReference.get();
    return database
        .collection('dashboard_draft_tracks')
        .doc(documentId)
        .set(document.data())
        .then(() => documentReference.delete())
        .finally(() =>
            dispatch({
                type: DATABASE_EDIT_FINISH
            }));
};

export const setDraftProjectAsPublished = (documentId) => async (dispatch) => {
    dispatch({
        type: DATABASE_EDIT_SUBMIT
    });
    const documentReference = database.collection('dashboard_draft_tracks').doc(documentId);
    const document = await documentReference.get();
    return database
        .collection('tracks')
        .doc(documentId)
        .set(document.data())
        .then(() => documentReference.delete())
        .finally(() =>
            dispatch({
                type: DATABASE_EDIT_FINISH
            }));
};

export const updateOrCreateProject = (documentId, updates, status = 'published') => (dispatch) => {
    const collectionName = status === 'published' ? 'tracks' : 'dashboard_draft_tracks';
    dispatch({
        type: DATABASE_EDIT_SUBMIT
    });
    const documentReference = database
        .collection(collectionName)
        .doc(documentId);

    return documentReference.get()
        .then((doc) => {
            const fn = doc.exists ? 'update' : 'set';
            return documentReference[fn](updates);
        })
        .finally(() =>
            dispatch({
                type: DATABASE_EDIT_FINISH
            }));
};

export const updateOrCreateDraftProject = (documentId, updates) => (dispatch) =>
    updateOrCreateProject(documentId, updates, 'draft')(dispatch);

export const listenToGameStep = (documentId) => (dispatch) => {
    dispatch({
        type: LISTEN_TO_GAME_STARTED,
        documentId
    });
    return database
        .collection('games')
        .doc(documentId)
        .onSnapshot(
            (snapshot) => {
                dispatch({
                    type: LISTEN_TO_GAME_RECEIVED_DATA,
                    documentId,
                    game: snapshot.data()
                });
            },
            (error) => {
                dispatch({
                    type: LISTEN_TO_GAME_RECEIVED_ERROR,
                    documentId,
                    error
                });
            }
        );
};

export const updateOrCreateGameStep = (documentId, projectDocumentId, step, templateNumber, gameStep, nextStepRef) => (dispatch) => {
    if (!documentId || !projectDocumentId || !step || !templateNumber || !gameStep) {
        return null;
    }
    dispatch({
        type: DATABASE_EDIT_SUBMIT
    });
    const updates = ({
        ...gameStep,
        step,
        template: templateNumber,
        trackRef: projectDocumentId,
        ...nextStepRef && {
            nextStepRef
        }
    });
    const documentReference = database
        .collection('games')
        .doc(documentId);

    return documentReference.get()
        .then((doc) => {
            const fn = doc.exists ? 'update' : 'set';
            return documentReference[fn](updates);
        })
        .finally(() =>
            dispatch({
                type: DATABASE_EDIT_FINISH
            }));
};
