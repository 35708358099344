import React, { useMemo, memo } from 'react';
import { Switch, Route, Redirect, Link, useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import { Stepper, Step, StepLabel } from '@material-ui/core';

import { ErrorTypography } from '../../../../small_views/error_typography/error_typography';
import { GameStep } from './game_step/game_step';

import { styles } from './game_steps_styles';

const useStyles = makeStyles(styles);

const GameStepsComponent = ({ documentId, status, project, draftProject }) => {
    const classes = useStyles();
    const params = useParams();
    const currentGameStepIndex = useMemo(() => params && parseInt(params.gameStep, 10), [params]);
    const baseProject = useMemo(() => project || draftProject, [project, draftProject]);
    const gameRefs = useMemo(() => baseProject && baseProject.gameRefs, [baseProject]);
    const baseProjectNames = useMemo(() => baseProject && baseProject.names, [baseProject]);

    const currentGameStepDocumentId = useMemo(() => gameRefs && gameRefs[currentGameStepIndex - 1].id, [
        gameRefs,
        currentGameStepIndex
    ]);

    if (!baseProject) {
        return <ErrorTypography error="Ce projet n'est pas valide." />;
    }

    if (!gameRefs || !gameRefs.length) {
        return (
            <ErrorTypography error="Le projet ne dispose pas d'un nombre d'étapes. Avez-vous bien rempli les paramètres généraux ?" />
        );
    }

    return (
        <>
            <GameStepsStepper
                {...{
                    documentId,
                    currentGameStepIndex,
                    status,
                    gameRefs,
                    classes
                }}
            />
            <GameStep
                projectDocumentId={documentId}
                documentId={currentGameStepDocumentId}
                {...{
                    gameRefs,
                    currentGameStepIndex,
                    status,
                    baseProjectNames
                }}
            />
        </>
    );
};

const GameStepsStepper = memo(({ documentId, status, currentGameStepIndex, gameRefs, classes }) => {
    if (!gameRefs) {
        return null;
    }
    return (
        <Stepper classes={{ root: classes.stepper }} nonLinear activeStep={currentGameStepIndex - 1} alternativeLabel>
            {gameRefs.map((id, index) => (
                <Step key={`game_steps_stepper_step_${id}`} classes={{ root: classes.step }}>
                    <StepLabel>
                        <Link to={`/edit-project/${status}/${documentId}/game-steps/${index + 1}`}>
                            {`Étape n° ${index + 1}`}
                        </Link>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
});

const WithRoutesGameStepsComponent = memo((props) => {
    const { documentId, stepParam, status } = props;
    return (
        <Switch>
            <Route
                path="/edit-project/:status/:documentId/:step/:gameStep"
                render={() => <GameStepsComponent {...props} />}
            />
            <Redirect
                from="/edit-project/:status/:documentId/:step"
                to={`/edit-project/${status}/${documentId}/${stepParam}/1`}
            />
        </Switch>
    );
});

export const GameSteps = WithRoutesGameStepsComponent;
