import React from 'react';

import { RegularTextField } from '../regular_text_field/regular_text_field';

const ColorTextFieldComponent = (props) => (
    <RegularTextField
        {...props}
        type="color"
    />
);

export const ColorTextField = ColorTextFieldComponent;
