import firebase from '../../firebase';

import {
    DATABASE_EDIT_SUBMIT,
    DATABASE_EDIT_FINISH
} from '../types';

const storage = firebase.storage();

export const uploadFileToStorage = (file, fileName, path) => (dispatch) => {
    dispatch({
        type: DATABASE_EDIT_SUBMIT
    });
    const finalFileName = fileName || file.name;
    return storage.ref().child(`${path}/${finalFileName}`).put(file)
        .then(async (snapshot) => {
            dispatch({
                type: DATABASE_EDIT_FINISH
            });
            const downloadUrl = snapshot.ref.getDownloadURL();
            return downloadUrl;
        });
}
