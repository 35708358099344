import * as Yup from 'yup';

import { RegularTextField } from '../../../../../../small_views/formik_components/regular_text_field/regular_text_field';
import { SelectBadgeField } from '../../../../../../small_views/formik_components/select_badge_field/select_badge_field';
import { ColorTextField } from '../../../../../../small_views/formik_components/color_text_field/color_text_field';
import { ContentTypeField } from '../../../../../../small_views/formik_components/content_type_field/content_type_field';
import { NumberTextField } from '../../../../../../small_views/formik_components/number_text_field/number_text_field';
import { CheckboxField } from '../../../../../../small_views/formik_components/checkbox_field/checkbox_field';
import { GameStepFormUrlUploadField } from '../game_step_form/game_step_form_custom_fields/game_step_form_url_upload_field';

export const LOCATION_FIELDS = {
    questionFR: {
        label: 'Question - FR',
        path: 'questions.fr',
        component: RegularTextField,
        validationRule: () =>
            Yup.string()
                .required('Ce champ est obligatoire.')
                .min(5, 'Ce champ doit contenir au moins 5 caractères.')
    },
    nameEN: {
        label: 'Question - EN',
        path: 'questions.en',
        component: RegularTextField,
        validationRule: () =>
            Yup.string()
                .required('Ce champ est obligatoire.')
                .min(5, 'Ce champ doit contenir au moins 5 caractères.')
    },
    locationLat: {
        label: 'Réponse - Latitude',
        path: 'location.latitude',
        component: NumberTextField,
        type: 'number',
        validationRule: () =>
            Yup.number()
                .required('Ce champ est obligatoire.')
    },
    locationLongitude: {
        label: 'Réponse - Longitude',
        path: 'location.longitude',
        component: NumberTextField,
        type: 'number',
        validationRule: () =>
            Yup.number()
                .required('Ce champ est obligatoire.')
    },
    rewardFR: {
        label: 'Récompense - FR',
        path: 'rewards.fr',
        component: RegularTextField,
        validationRule: () =>
            Yup.string()
                .required('Ce champ est obligatoire.')
                .min(5, 'Ce champ doit contenir au moins 5 caractères.')
    },
    rewardEN: {
        label: 'Récompense - EN',
        path: 'rewards.en',
        component: RegularTextField,
        validationRule: () =>
            Yup.string()
                .required('Ce champ est obligatoire.')
                .min(5, 'Ce champ doit contenir au moins 5 caractères.')
    },
    backgroundColor: {
        label: 'Couleur de fond',
        path: 'backgroundColor',
        component: ColorTextField,
        defaultValue: '#1f1f1f',
        validationRule: () =>
            Yup.string()
                .matches(/^#[0-9a-f]{3,6}$/i, 'La couleur doit être hexadécimale.')
                .required('Ce champ est obligatoire.')
    },
    contentType: {
        label: 'Type de contenu',
        path: 'contentType',
        component: ContentTypeField,
        validationRule: () =>
            Yup.string()
                .required('Ce champ est obligatoire.')
    },
    contentURL: {
        label: 'URL du contenu',
        path: 'content',
        component: GameStepFormUrlUploadField,
        validationRule: () =>
            Yup.string()
                .url('Ce champ doit contenir une URL.')
                .required('Ce champ est obligatoire.')
    },
    badgeId: {
        label: 'ID du badge',
        component: SelectBadgeField,
        path: 'badgeRef',
        validationRule: () => Yup.string().required('Un badge est requis. Cliquez pour en choisir un !')
    },
    isFinal: {
        label: "Indiquer à l'application qu'il s'agit de la dernière étape",
        component: CheckboxField,
        path: 'isFinal',
        type: 'boolean',
        validationRule: () => Yup.boolean()
    }
};
