export const styles = (theme) => ({
    container: ({ containerWidth }) => ({
        height: `${containerWidth * (722 / 360)}px`,
        width: '100%',
        position: 'relative',
        marginBottom: theme.spacing(5)
    }),
    background: {
        transition: 'fill 500ms'
    },
    contentContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex'
    },
    content: {
        height: 'auto',
        width: '100%',
        padding: theme.spacing(5, 2, 0),
        margin: 20,
        borderRadius: 25,
        overflow: 'hidden',
        wordBreak: 'break-all',
        overflowY: 'auto'
    }
});
