import React, { useCallback, useMemo, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Button, CircularProgress } from '@material-ui/core';

import { ContentHeader } from '../../small_views/content_header/content_header';
import { ProjectsListTable } from './projects_list_table/projects_list_table';
import { CreateDraftProjectDialog } from '../../small_views/create_draft_project_dialog/create_draft_project_dialog';
import { ErrorTypography } from '../../small_views/error_typography/error_typography';

import { useProjects } from '../../../state/fetchers/projects/use_projects';
import { useDraftProjects } from '../../../state/fetchers/projects/use_draft_projects';
import { useDashboardUserRole, useUid } from '../../../state/selectors/user_selectors';

import { styles } from './projects_list_styles';

const useStyles = makeStyles(styles);

const ProjectsListComponent = () => {
    const classes = useStyles();
    const role = useDashboardUserRole();
    const uid = useUid();
    const isAdmin = useMemo(() => role === 'admin', [role]);
    const { projects, loading: loadingProjects, error: errorProjects } = useProjects(!isAdmin && uid);
    const { draftProjects, loading: loadingDraftProjects, error: errorDraftProjects } = useDraftProjects(
        !isAdmin && uid
    );

    const projectsLength = useMemo(() => projects && Object.keys(projects).length, [projects]);
    const draftProjectsLength = useMemo(() => draftProjects && Object.keys(draftProjects).length, [draftProjects]);
    const totalProjectsLength = useMemo(() => projectsLength + draftProjectsLength, [
        projectsLength,
        draftProjectsLength
    ]);

    const isLoading = useMemo(() => Boolean(loadingProjects || loadingDraftProjects), [
        loadingProjects,
        loadingDraftProjects
    ]);
    const hasError = useMemo(() => errorProjects || errorDraftProjects, [errorProjects, errorDraftProjects]);

    return (
        <>
            <Header {...{ projectsLength, draftProjectsLength, totalProjectsLength }} />
            <div className={classes.container}>
                <Content
                    {...{
                        isLoading,
                        hasError,
                        projects,
                        draftProjects
                    }}
                />
            </div>
        </>
    );
};

const Header = ({ projectsLength, draftProjectsLength, totalProjectsLength }) => {
    const [openDialog, setDialogOpenState] = useState(false);
    const setDialogOpened = useCallback(() => setDialogOpenState(true), []);
    const setDialogClosed = useCallback(() => setDialogOpenState(false), []);
    return (
        <>
            <CreateDraftProjectDialog open={openDialog} onClose={setDialogClosed} />
            <ContentHeader
                title={`${totalProjectsLength ? `${totalProjectsLength} ` : ''} Scénarios`}
                {...((projectsLength || draftProjectsLength) && {
                    subtitle: `(${draftProjectsLength ? `${draftProjectsLength} Brouillons` : ''}${
                        draftProjectsLength && totalProjectsLength ? ' / ' : ''
                    }${projectsLength ? `${projectsLength} Publiés` : ''})`
                })}
            >
                <Button color="primary" variant="contained" onClick={setDialogOpened}>
                    {'Créer un scénario'}
                </Button>
            </ContentHeader>
        </>
    );
};

const Content = ({ isLoading, hasError, projects, draftProjects }) => {
    if (isLoading) {
        return <CircularProgress />;
    }
    if (hasError) {
        return <ErrorTypography error={hasError} />;
    }
    if (projects || draftProjects) {
        return <ProjectsListTable {...{ projects, draftProjects }} />;
    }
    return null;
};

export const ProjectsList = ProjectsListComponent;
