import * as Yup from 'yup';

import { RegularTextField } from '../../../../small_views/formik_components/regular_text_field/regular_text_field';
import { NumberTextField } from '../../../../small_views/formik_components/number_text_field/number_text_field';
import { MultilineTextField } from '../../../../small_views/formik_components/multiline_text_field/multiline_text_field';
import { SelectBadgeField } from '../../../../small_views/formik_components/select_badge_field/select_badge_field';

import firebase from '../../../../../firebase/index';

const database = firebase.firestore();

const pad = (n, length) => new Array(length - `${n}`.length).fill().reduce((acc) => `0${acc}`, n);

export const GENERAL_FIELDS = [
    {
        name_fr: {
            label: 'Nom du scnénario - FR',
            path: 'names.fr',
            component: RegularTextField,
            validationRule: () =>
                Yup.string()
                    .required('Ce champ est obligatoire.')
                    .min(5, 'Ce champ doit contenir au moins 5 caractères.')
        },
        name_en: {
            label: 'Nom du scnénario - EN',
            path: 'names.en',
            component: RegularTextField,
            validationRule: () =>
                Yup.string()
                    .required('Ce champ est obligatoire.')
                    .min(5, 'Ce champ doit contenir au moins 5 caractères.')
        },
        deadline: {
            label: 'Temps (Si nécessaire, en secondes)',
            path: 'time',
            type: 'number',
            component: NumberTextField,
            validationRule: () =>
                Yup.number()
                    .required('Ce champ est obligatoire.')
                    .min(0, 'Le temps ne peut pas être négatif.')
        },
        points: {
            label: 'Points',
            path: 'points',
            type: 'number',
            component: NumberTextField,
            validationRule: () =>
                Yup.number()
                    .required('Ce champ est obligatoire.')
                    .min(0, 'Le nombre de points ne peut pas être négatif.')
        },
        stepsNumber: {
            label: "Nombre d'étapes",
            path: 'gameRefs',
            type: 'number',
            initialTransform: (data) => data && Object.keys(data).length,
            submitTransform: (value, { documentId }) =>
                new Array(value)
                    .fill()
                    .map((empty, index) => database.collection('games').doc(`${documentId}${pad(index + 1, 3)}`)),
            component: NumberTextField,
            validationRule: () =>
                Yup.number()
                    .required('Ce champ est obligatoire.')
                    .min(2, 'Vous devez indiquer au moins deux étapes.')
                    .max(999, 'Vous ne pouvez pas indiquer plus de 999 étapes.')
        },
        badgeId: {
            label: 'ID du badge',
            component: SelectBadgeField,
            path: 'badgeRef',
            validationRule: () => Yup.string().required('Un badge est requis. Cliquez pour en choisir un !')
        }
    },
    {
        notificationTitleFR: {
            label: 'Titre de la notification - FR',
            path: 'titleNotif.fr',
            component: RegularTextField,
            validationRule: () =>
                Yup.string()
                    .required('Ce champ est obligatoire.')
                    .min(5, 'Ce champ doit contenir au moins 5 caractères.')
        },
        notificationTitleEN: {
            label: 'Titre de la notification - EN',
            path: 'titleNotif.en',
            component: RegularTextField,
            validationRule: () =>
                Yup.string()
                    .required('Ce champ est obligatoire.')
                    .min(5, 'Ce champ doit contenir au moins 5 caractères.')
        },
        shortNotificationTextFR: {
            label: 'Texte court de la notification - FR',
            path: 'bodyNotif.fr',
            component: RegularTextField,
            validationRule: () =>
                Yup.string()
                    .required('Ce champ est obligatoire.')
                    .min(5, 'Ce champ doit contenir au moins 5 caractères.')
        },
        shortNotificationTextEN: {
            label: 'Texte court de la notification - EN',
            path: 'bodyNotif.en',
            component: RegularTextField,
            validationRule: () =>
                Yup.string()
                    .required('Ce champ est obligatoire.')
                    .min(5, 'Ce champ doit contenir au moins 5 caractères.')
        },
        longNotificationTextFR: {
            label: 'Texte long de la notification - FR',
            path: 'bodyPreNotif.fr',
            component: MultilineTextField,
            validationRule: () =>
                Yup.string()
                    .required('Ce champ est obligatoire.')
                    .min(5, 'Ce champ doit contenir au moins 5 caractères.')
        },
        longNotificationTextEN: {
            label: 'Texte long de la notification - EN',
            path: 'bodyPreNotif.en',
            component: MultilineTextField,
            validationRule: () =>
                Yup.string()
                    .required('Ce champ est obligatoire.')
                    .min(5, 'Ce champ doit contenir au moins 5 caractères.')
        }
    }
];
