import { flex } from '../../../../../../../../utils/styles_utils';

const { center } = flex;

export const styles = (theme) => ({
    container: {
        marginTop: theme.spacing(2)
    },
    paper: {
        width: '100%',
        height: 100,
        padding: theme.spacing(2),
        ...center
    }
});
