import firebase from '../firebase';

import {
    DATABASE_EDIT_SUBMIT,
    DATABASE_EDIT_FINISH,
    LISTEN_TO_GAME_USERS_STARTED,
    LISTEN_TO_GAME_USERS_RECEIVED_DATA,
    LISTEN_TO_GAME_USERS_RECEIVED_ERROR
} from './types';

import { getObjectFromCollectionSnapshot } from '../utils/firestore_utils';

const database = firebase.firestore();

export const listenToGameUsers = () => (dispatch) => {
    dispatch({
        type: LISTEN_TO_GAME_USERS_STARTED
    });
    return database.collection('users').onSnapshot((snapshot) => {
        dispatch({
            type: LISTEN_TO_GAME_USERS_RECEIVED_DATA,
            gameUsers: getObjectFromCollectionSnapshot(snapshot)
        });
    }, (error) => {
        dispatch({
            type: LISTEN_TO_GAME_USERS_RECEIVED_ERROR,
            error
        });
    });
};

export const deleteGameUser = (documentId) => (dispatch) => {
    dispatch({
        type: DATABASE_EDIT_SUBMIT
    });
    return database.collection('users').doc(documentId).delete()
        .finally(() => {
            dispatch({
                type: DATABASE_EDIT_FINISH
            });
        });
};
